export type Address = {
    street: string;
    postalCode: string;
    city: string;
    country: string
}

export module Address {
    export function createDefault(): Address {
        return {street: "Placeholder", postalCode: "Placeholder", city: "Placeholder", country: "Placeholder"};
    }

    export function toString(address: Address) {
      return `${address.city}, ${address.street}`
    }
}
