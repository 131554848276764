import css from "./TitledSection.module.scss";
import React from "react";
import { Section } from "./Section";
import { classNames } from "@greeter/util";
import { IonIcon } from "@ionic/react";
import { Props } from "@greeter/matter";

export type TitledSectionProps = Props & {
  title: string;
  padding?: boolean;
  hint?: string;
  icon?: string;
  center?: boolean;
};

export const TitledSection: React.FC<TitledSectionProps> = ({
  id,
  children,
  title,
  className = "",
  padding,
  hint,
  icon,
  center = false,
}) => (
  <Section id={id} padding={padding} {...classNames(css.TitledSection, className)}>
    <div
      className={css.Title}
      style={{
        display: "flex",
        justifyContent: center ? "center" : "space-between",
      }}
    >
      <div>
        <h2 style={{ margin: 0 }}>{title}</h2>
        {hint && <div style={{ fontSize: "1rem", opacity: 0.6 }}>{hint}</div>}
      </div>
      {icon && (
        <IonIcon icon={icon} style={{ width: "1.5rem", height: "1.5rem" }} />
      )}
    </div>
    {children}
  </Section>
);
