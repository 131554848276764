import React, { useMemo } from "react";
import { doNothing } from "@greeter/util";
import { OnCityChange, OnThemeChange, VenueOverviewPage } from "./VenueOverviewPage";
import { useDefaultGuestApi } from "@greeter-guest/api/api";
import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query";
import { flatten } from "lodash";
import { useCitiesQuery } from "@greeter/guest-api-hooks";

export type VenueOverviewPageApiHandlerProps = {
  themeId?: string;
  activityId?: string;
  onThemeChange?: OnThemeChange;
  country: string;
  onCityChange?: OnCityChange;
  city: string;
};

export const VenueOverviewPageApiHandler: React.FC<
  VenueOverviewPageApiHandlerProps
> = ({ activityId, themeId = "all", onThemeChange = doNothing, onCityChange = doNothing, country, city }) => {
  const api = useDefaultGuestApi();
  const queryClient = useQueryClient();

  const cities = useCitiesQuery(api, country);

  const venuesQuery = useInfiniteQuery(
    ["venues", city, activityId, themeId],
    async ({ pageParam = 0 }) => {
      // TODO: Change to dynamic location
      if (themeId === "all") {
        const venues = await api.fetchVenues({
          page: pageParam,
          city: city,
          country: country,
        });
        return venues;
      } else if (themeId || activityId)
        return await api.fetchVenues({
          page: pageParam,
          city: city,
          country: country,
          activityId,
          themeId,
        });
      else return [];
    },
    { getNextPageParam: (lastPage, pages) => pages.length }
  );

  const activityQuery = useQuery(["activities", activityId], async () => {
    if (activityId) {
      const activities = await api.fetchActivities();
      activities.forEach((a) => {
        queryClient.setQueryData(["activity", a.id], a);
      });
      return activities.find((a) => a.id === activityId);
    }
  });
  const themesQuery = useQuery(
    ["themes", activityQuery.data?.id],
    async () => await api.fetchThemes({ activityId: activityQuery.data?.id })
  );

  const venues = useMemo(
    () => flatten(venuesQuery.data?.pages),
    [venuesQuery.data]
  );

  return (
    <VenueOverviewPage
      activity={activityQuery.data}
      themes={themesQuery.data ?? []}
      venues={venues}
      selectedThemeId={themeId}
      onThemeChange={onThemeChange}
      cities={cities.data ?? []}
      city={city}
      onCityChange={onCityChange}
    />
  );
};
