import { differenceInMilliseconds } from "date-fns";
import { z, ZodTypeAny } from "zod";

function replacer(key: any, value: any) {
  if(value instanceof Map) {
    return {
      __type: 'Map',
      value: Array.from(value.entries()), // or with spread: value: [...value]
    };
  } else {
    return value;
  }
}

function reviver(key: any, value: any) {
  if(typeof value === 'object' && value !== null && value.__type === 'Map') {
    const result = new Map(value.value);
    return result;
  }
  return value;
}

export function parsify(s: string): any {
  return JSON.parse(s, reviver);
}

export function jsonify(obj: any, settings?: { indent?: number }): string {
  return JSON.stringify(obj, replacer, settings?.indent);
}

type Milliseconds = number;
export type PersistedCachePolicy = {
  staleAfter: Milliseconds;
};
const PersistedData = z.object({
  data: z.unknown(),
  savedAt: z
    .string()
    .datetime()
    .transform((s) => new Date(s)),
});
export type PersistedData = z.infer<typeof PersistedData>;

/**
 * Provides a simple caching mechanism for LocalStorage.
 *
 * Through the policy provided it will maintain the data in memory on first load and delete it
 * if retrieved once the policy has been violated.
 */
export class PersistedCache<T> {
  /**
   * We keep these values so that after the first load, we dont have to access the memory again
   *
   * We can keep the data up to date simply by reupdating on .save();
   */
  private inMem?: PersistedData;
  private parsed?: T;

  constructor(
    private id: string,
    private schema: ZodTypeAny,
    private policy: PersistedCachePolicy
  ) {}

  clear() {
    localStorage.removeItem(this.id);
  }

  /**
   * Returns 'undefined' if expired
   */
  get(): T | undefined {
    if (this.parsed) {
      return this.parsed;
    }

    const now = new Date();
    try {
      if (!this.inMem) {
        const saved = localStorage.getItem(this.id);
        if (saved) {
          this.inMem = PersistedData.parse(parsify(saved));
          this.parsed = this.schema.parse(this.inMem.data);
        } else {
          return undefined;
        }
      }
    } catch (error) {
      console.warn(error);
      this.clear();
      return undefined;
    }

    if (
      differenceInMilliseconds(this.inMem.savedAt, now) > this.policy.staleAfter
    ) {
      console.warn("Cached value is stale. Deleting...");
      this.clear();
      return undefined;
    }

    return this.parsed;
  }

  save(t: T): void {
    const toSave: PersistedData = { data: t, savedAt: new Date() };
    this.inMem = toSave;
    this.parsed = t;
    localStorage.setItem(this.id, jsonify(toSave));
  }
}
