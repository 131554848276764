import { z } from "zod";
import { DayOfWeek, TimeOfDay, convertToDay } from "@greeter/date";
import { WeeklyOpeningHours } from "@greeter/core";
import { CreateBookingContext, createBookingMachine } from "./CreateBookingMachine";
import { ActorRef, SnapshotFrom } from "xstate";

const dateStringToDate = z
  .string()
  .datetime()
  .transform((s) => new Date(s));
export const DatePeriodSchema = z.object({
  from: dateStringToDate,
  to: dateStringToDate,
});
export const BookingSchema = z.object({
  id: z.string(),
  createdAt: dateStringToDate,
  period: DatePeriodSchema,
  tables: z.any(),
  status: z.string(),
  order: z.nullable(z.any()),
  customerId: z.string(),
  venueId: z.string(),
  createdBy: z.string(),
  comment: z.string(),
});
export const TableIdSchema = z.object({
  tableNumber: z.number(),
  area: z.string(),
});
export type BookingSchema = z.infer<typeof BookingSchema>;
export const SpecialOpeningHoursSchema = z.object({
  id: z.string(),
  period: DatePeriodSchema,
});

export const OpeningHoursSchema = z.object({
  from: z.string().transform((s) => TimeOfDay.parse(s)),
  to: z.string().transform((s) => TimeOfDay.parse(s)),
  isOpen: z.boolean(),
});
export type OpeningHoursSchema = z.infer<typeof OpeningHoursSchema>;

function toWeeklyOpeningHours(obj: [string, any][]) {
  const map = new WeeklyOpeningHours();
  for (const [k, v] of obj) {
    map.set(convertToDay(k as DayOfWeek), v);
  }
  return map;
}

function toBookingsByFromDate(o: any) {
  const m = new Map<string, BookingSchema>();

  if (!o) {
    return m;
  }

  for (const [key, v] of Object.entries(o)) {
    if (key && v) {
      m.set(key, BookingSchema.parse(v));
    } else {
      console.error("Why is key and v null|undefined?", key, v);
    }
  }
  return m;
}

export const VenueSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  address: z.any(),
  logoUrl: z.string(),
  logoAsset: z.any(),
  coverUrl: z.string(),
  coverAsset: z.any(),
  cvr: z.string(),
  verified: z.boolean(),
  dressCode: z.string(),
  launchDate: z
    .string()
    .datetime()
    .transform((s) => new Date(s)),
  openingHours: z
    .array(z.tuple([z.string(), OpeningHoursSchema]))
    .transform(toWeeklyOpeningHours),
  arrivalOpeningHours: z
    .array(z.tuple([z.string(), OpeningHoursSchema]))
    .transform(toWeeklyOpeningHours),
  bookableOpeningHours: z
    .array(z.tuple([z.string(), OpeningHoursSchema]))
    .transform(toWeeklyOpeningHours),
  musicGenres: z.array(z.any()),
  themes: z.array(z.any()),
});
export const CreateBookingContextSchema = z.object({
  loginStatus: z.string(),
  comment: z.string(),
  product: z.any(),
  //products: z.array(z.any()), // We dont care to specify this
  orderLines: z.array(z.any()), // We dont care to specify this
  orderTotal: z.number(),
  minTotal: z.number(),

  openingHour: z.optional(DatePeriodSchema),
  openingHours: z.array(DatePeriodSchema).default([]),

  arrivalDate: z.optional(dateStringToDate),
  arrivalTime: z.optional(dateStringToDate),
  arrivalTimes: z.array(dateStringToDate),
  specialOpeningHours: z.array(SpecialOpeningHoursSchema),
  selectedTables: z.array(TableIdSchema),
  groupSize: z.optional(z.number()),
  bookingBuffer: z.optional(z.string().transform((s) => TimeOfDay.parse(s))),
  now: dateStringToDate,
  ticks: z.number(),
  venue: z.optional(VenueSchema),
  booking: z.optional(BookingSchema),
  bookings: z.array(BookingSchema).default([]),
  bookingsByOpeningHoursFromDate: z.map(z.string(), BookingSchema).default(new Map()),
});
export type CreateBookingContextSchema = z.infer<
  typeof CreateBookingContextSchema
>;

export const CreateBookingSnapshotSchema = z.object({
  children: z.any(),
  context: CreateBookingContextSchema,
  historyValue: z.any(),
  status: z.string(),
  value: z.string(),
});
export type CreateBookingSnapshotSchema = z.infer<
  typeof CreateBookingSnapshotSchema
>;

type AssertEqual<T, U extends T> = U;
type Test = AssertEqual<CreateBookingSnapshotSchema, SnapshotFrom<typeof createBookingMachine>>;
