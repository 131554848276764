import { addDays, isAfter, isBefore } from "date-fns";
import { TimeOfDay, withTimeOfDay } from ".";

export class InvalidPeriodError extends Error {
  constructor(period: DatePeriod) {
    super(`Period ${period} is invalid. From should be after to.`);
  }
}

export interface DatePeriodArgs {
  from: Date;
  to: Date;
}

export class DatePeriod {
  private _from!: Date;
  private _to!: Date;

  constructor(args: DatePeriodArgs) {
    this._from = args.from;
    this._to = args.to;

    if (isAfter(this._from, this._to)) {
      throw new InvalidPeriodError(this);
    }
  }

  get from() {
    return this._from;
  }
  set from(val: Date) {
    if (isAfter(val, this._to)) {
      throw new InvalidPeriodError(this);
    }

    this._from = val;
  }

  get to() {
    return this._to;
  }
  set to(val: Date) {
    if (isBefore(val, this._to)) {
      throw new InvalidPeriodError(this);
    }

    this._to = val;
  }

  isWithin(date: Date) {
    return date >= this.from && date <= this.to;
  }

  overlaps(period: DatePeriod) {
    return (
      period.isWithin(this.from) ||
      period.isWithin(this.to) ||
      this.isWithin(period.from) ||
      this.isWithin(period.to)
    );
  }

  toJSON(): any {
    const { from, to } = this;
    return { from, to };
  }

  static fromTimePeriod(date: Date, start: TimeOfDay, end: TimeOfDay) {
    return new DatePeriod({
      from: withTimeOfDay(date, start),
      to:
        end.getTotalSeconds() < start.getTotalSeconds()
          ? addDays(withTimeOfDay(date, end), 1)
          : withTimeOfDay(date, end),
    });
  }

  toString(): string {
    return `${this.from.toISOString()} - ${this.to.toISOString()}`;
  }
}
