import { ApiError, IGuestApi } from "@greeter/api";
import { Customer, TableServiceOrder, Venue } from "@greeter/core";
import { uniq } from "lodash";
import { useQuery, UseQueryOptions, QueryClient } from "@tanstack/react-query";

export const useVenueQuery = (api: IGuestApi, venueId: string) =>
  useQuery<Venue, Error, Venue, string[]>(
    ["venue", venueId],
    async () => await api.fetchVenue(venueId)
  );
export const useVenuesQuery = (
  api: IGuestApi,
  venueIds: string[] = [],
  client?: QueryClient
) =>
  useQuery(
    ["venues", ...uniq(venueIds)],
    async () => await api.fetchVenues({ ids: venueIds }),
    {
      enabled: venueIds && venueIds.length > 0,
      onSuccess: (data) => {
        for (let i = 0; i < data.length; i++) {
          const v = data[i];
          client?.setQueryData(["venue", v.id], v);
        }
      },
    }
  );
export const useGreeterEventsQuery = (api: IGuestApi, venueId?: string) =>
  useQuery(["greeterEvents", venueId], async () =>
    venueId
      ? await api.fetchUpcomingGreeterEventsForVenue({ venueId })
      : await api.fetchUpcomingGreeterEvents({
          city: "Aalborg",
          country: "Denmark",
        })
  );
export const useGreeterEventsByCityQuery = (
  api: IGuestApi,
  country: string,
  city: string
) =>
  useQuery(
    ["greeterEvents", country, city],
    async () =>
      await api.fetchUpcomingGreeterEvents({
        city: city,
        country: country,
      })
  );
export const useActivitiesQuery = (api: IGuestApi, venueId: string) =>
  useQuery(
    ["activities", venueId],
    async () => await api.fetchVenueActivities(venueId)
  );
export const useThemesQuery = (api: IGuestApi, venueId: string) =>
  useQuery(
    ["themes", venueId],
    async () => await api.fetchThemesByVenue(venueId)
  );
export const useProductsQuery = (api: IGuestApi, venueId: string) =>
  useQuery(
    ["products", venueId],
    async () => await api.fetchProductsByVenue(venueId)
  );
export const useDaysliceConfigQuery = (api: IGuestApi) =>
  useQuery(["daysliceConfig"], async () => await api.fetchDaysliceConfig(), {
    enabled: false, // Just use the placeholder data
    placeholderData: {
      dayOffset: 0,
      dayStart: 0,
      eveningStart: 18,
      nightStart: 22,
    },
  });
export function useCustomerQuery(
  api: IGuestApi,
  options?:
    | Omit<
        UseQueryOptions<Customer, ApiError, Customer, string[]>,
        "queryKey" | "queryFn"
      >
    | undefined
) {
  return useQuery(["customer"], async () => await api.fetchCustomer(), {
    retry: Infinity,
    ...options,
  });
}

export const useTableServiceOrderQuery = (
  api: IGuestApi,
  id: string,
  options?:
    | Omit<
        UseQueryOptions<TableServiceOrder, Error, TableServiceOrder, string[]>,
        "queryKey" | "queryFn"
      >
    | undefined
) =>
  useQuery(
    ["tableServiceOrder", id],
    async () => await api.fetchTableServiceOrder(id),
    options
  );

export function useCitiesQuery(
  api: IGuestApi,
  country: string,
  options?:
    | Omit<
        UseQueryOptions<string[], Error, string[], string[]>,
        "queryKey" | "queryFn"
      >
    | undefined
) {
  return useQuery(
    ["cities"],
    async () => await api.fetchCities(country),
    options
  );
}
