import React, { useEffect, useMemo, useState } from "react";

import { Product } from "@greeter/core";
import { isEmpty } from "@greeter/util";
import { logger } from "@greeter/log";
import { Swiper, SwiperSlide, SwiperClass } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { ExpandableTablePackageCard } from "../ExpandableTablePackageCard";

import { first, partial, sortBy } from "lodash";

import css from "./ProductsCarousel.module.scss";

const log = logger("[ProductsCarousel]");

export type ProductsCarouselProps = React.PropsWithChildren &{
  className?: string;
  product?: Product;
  products: Product[];
  onProductChanged: (product: Product) => void;
};

export function ProductsCarousel({
  onProductChanged,
  products,
  product,
}: ProductsCarouselProps) {
  const [carouselController, setCarouselController] = useState<SwiperClass>();

  const sortedProducts = useMemo(() => {
    return Product.sortByPriceAndPriority(products ?? []);
  }, [products]);

  useEffect(() => {
    if (carouselController && !isEmpty(sortedProducts) && product) {
      const foundProduct = sortedProducts.find((p) => p.id === product.id);
      if (foundProduct) {
        const foundProductIndex = sortedProducts.indexOf(foundProduct);
        carouselController?.slideTo(foundProductIndex, 200);
      }
    }
  }, [product, carouselController, sortedProducts]);

  useEffect(() => {
    const firstProduct = first(sortedProducts);
    if (!product && firstProduct) {
      onProductChanged(firstProduct);
    }
  }, [onProductChanged, product, sortedProducts]);

  const wrappedProducts = useMemo(
    () =>
      sortedProducts.map((p, i) => {
        return (
          <SwiperSlide key={p.id}>
            {({ isActive }: { isActive: boolean }) => (
              <ExpandableTablePackageCard isActive={isActive} product={p} />
            )}
          </SwiperSlide>
        );
      }),
    [sortedProducts]
  );

  return (
    // So uuuh. MultiCarousel is acting funky. Using swiper until a better solution is made.
    <Swiper
      className={css.ProductsCarousel}
      spaceBetween={40}
      modules={[Navigation, Pagination]}
      style={{ overflow: "visible", zIndex: 0 }}
      onSlideChange={(swiper) =>
        onProductChanged(sortedProducts[swiper.activeIndex])
      }
      onSwiper={setCarouselController}
    >
      {wrappedProducts}
    </Swiper>
  );
}

export default ProductsCarousel;
