import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";

import { defineCustomElements } from "@stripe-elements/stripe-elements";

import "./Header";

// defineCustomElements();

// Sentry.init({
//   dsn: "https://6a8c6a3bebd4459e82d3d4ece92631b4@o4503926720102400.ingest.sentry.io/4503926728687617",
//   // Set your release version, such as "getsentry@1.0.0"
//   release: "greeter-guest",
//   // Set your dist version, such as "1"
//   dist: "1",
// });

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />,
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
