import React, { useEffect } from "react";
import { IonIcon, useIonRouter } from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import { useHistory } from "react-router-dom";

import css from "./FloatingBackButton.module.scss";
import { partial } from "lodash";

type GoBackFn = () => void;
type BackButtonOnClick = (originalFn: GoBackFn) => void;

function useCanGoBack() {
  const history = useHistory();

  useEffect(() => {
    window.addEventListener("beforeunload", partial(console.log, "beforeunload"))
    window.addEventListener("popstate", partial(console.log, "popstate"));
  }, [history]);

  return true;
}

export type BackButtonProps = React.PropsWithChildren & {
  fallback?: string;
  onClick?: BackButtonOnClick;
};

export function BackButton({
  children,
  fallback,
  onClick,
}: BackButtonProps) {
  const history = useHistory();
  const canGoBack = useCanGoBack();
  function goBack() {
    if (canGoBack) history.goBack();
    else history.push(fallback ?? "/");
  };
  return (
    <div
      className={css.BackButton}
      data-test-id="back-btn"
      onClick={() => {
        if (onClick) {
          onClick(goBack);
          return;
        }

        goBack();
      }}
    >
      <IonIcon icon={arrowBack} />
    </div>
  );
};

export type FloatingBackButtonProps = React.PropsWithChildren &
  BackButtonProps & {};

export function FloatingBackButton({
  children,
  ...props
}: FloatingBackButtonProps) {
  return (
    <div className={css.FloatingBackButton}>
      <BackButton {...props} />
    </div>
  );
};
