import { flatten, uniqBy } from "lodash";

export type TableId = {
  area: string;
  tableNumber: number;
};

export type Table = {
  type: "table" | "pool-table" | "football-table";
  number: number;
  minCapacity: number;
  maxCapacity: number;
  minPrice: number;
};

export module Table {
  export function tableIdsToMap(tableIds: TableId[]) {
    return new Map<string, TableId[]>(
      tableIds.map((t) => [
        t.area,
        tableIds.filter((innerT) => innerT.area === t.area),
      ])
    );
  }

  export function flattenTableIds(map: Map<string, TableId[]>): TableId[] {
    return uniqBy(
      flatten([...Object.entries(map)].map(([k, v]) => v)),
      (t) => `${t.area}${t.tableNumber}`
    );
  }

  export function toTableId(area: string, table: Table): TableId {
    return {
      area: area,
      tableNumber: table.number,
    };
  }

  export function toTableIds(map: Map<string, Table[]>): TableId[] {
    // We can handle both maps and objects
    const entries: [string, Table[]][] =
      map instanceof Map
        ? [...map.entries()] ?? []
        : Object.entries(map);

    const mapped: TableId[] = [];

    // Unfolded the functional approach to allow for easy debugging
    // It's much easier to debug procedural code than expressionful code.
    for (const [area, tables] of entries) {
      for (const table of tables) {
        mapped.push({ area: area, tableNumber: table.number });
      }
    }

    return mapped;
  }

  export function eqIds(a: TableId, b: TableId) {
    return a.area === b.area && a.tableNumber === b.tableNumber;
  }
}
