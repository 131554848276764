import { logger, warner } from "@greeter/log";
import { Env } from "@greeter/config";
import {
  Auth,
  UserInfo as FirebaseUserInfo,
  getIdToken,
  fetchSignInMethodsForEmail,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  createUserWithEmailAndPassword,
  signInAnonymously,
  signOut,
  signInWithRedirect,
  getRedirectResult,
  getIdTokenResult,
  updateEmail,
  updateProfile,
  User,
} from "firebase/auth";

import { EventEmitter, Handle } from "@greeter/event";
import { AuthChangedEvent, FirebaseAuthEvents, RedirectEvent } from "./events";
import { handleFirebase, mapFirebaseUserInfo, mapSignInMethods } from "./utils";
import { IAuthentication, SignInMethod, UserInfo } from "./IAuthentication";
import { getOrInitFirebaseAuth } from "./FirebaseHooks";

const logId = "[Auth]" + "[WebFirebaseAuth]";
const log = logger(logId);
const warn = warner(logId);

export class WebFirebaseAuth implements IAuthentication {
  private static _instance: WebFirebaseAuth;
  private emitter = new EventEmitter<FirebaseAuthEvents>();
  private auth: Auth;

  public constructor(firebaseConfig: any, env?: Env) {
    log("Initializing with config", firebaseConfig);
    this.auth = getOrInitFirebaseAuth(
      // env ?? ("[DEFAULT]" as Env),
      "[DEFAULT]" as Env,
      firebaseConfig
    );
    onAuthStateChanged(
      this.auth,
      (user: User | undefined | null) => {
        log("AUTH CHANGEDDD. Emitting...", user);
        this.emitter.emit({
          event: "authStateChanged",
          // There is a bug in firebase for WKWebKit (the browser used for in-app browsers)
          // where users in the authStateChange is undefined while the currentUser is something
          // This makes no sense. And so to combat this we're using the current
          data: mapFirebaseUserInfo(user ?? this.auth.currentUser),
        });
      }
    );
    this.auth.authStateReady().then(() => {
      this.emitter.emit({ event: "authStateReady", data: new Date() });
    });
    this.checkForRedirect();
  }

  async updateEmail(email: string): Promise<UserInfo> {
    if (!this.auth.currentUser) {
      throw new Error("No user to update");
    }

    await updateEmail(this.auth.currentUser, email);
    return mapFirebaseUserInfo(this.auth.currentUser)!;
  }

  async updateName(name: string): Promise<UserInfo> {
    if (!this.auth.currentUser) {
      throw new Error("No user to update");
    }

    await updateProfile(this.auth.currentUser, { displayName: name });
    return mapFirebaseUserInfo(this.auth.currentUser)!;
  }


  async refreshToken(): Promise<string> {
    if (this.auth.currentUser) {
      return (await getIdTokenResult(this.auth.currentUser, true)).token;
    }

    return "";
  }

  onAuthReady(listener: (d: Date) => void): Handle {
    return this.emitter.on("authStateReady", () => listener(new Date()));
  }

  public static createOrGetSingleton(
    firebaseConfig: any,
    env?: Env
  ): IAuthentication {
    if (!WebFirebaseAuth._instance) {
      WebFirebaseAuth._instance = new WebFirebaseAuth(firebaseConfig, env);
    }

    return WebFirebaseAuth._instance;
  }

  private async checkForRedirect() {
    const result = await getRedirectResult(this.auth);
    if (result) {
      log("Emitting redirect result...", result, this.emitter);
      const user = mapFirebaseUserInfo(result.user ?? this.auth.currentUser);
      this.emitter.emit({
        event: "redirect",
        data: user,
      });
      this.emitter.emit({
        event: "authStateChanged",
        data: user,
      });
    }
  }

  async signInAsAnon(): Promise<void> {
    return await handleFirebase(async () => {
      await signInAnonymously(this.auth);
    });
  }

  async fetchSignInMethods(email: string): Promise<SignInMethod[]> {
    return await handleFirebase(async () => {
      const methods = await fetchSignInMethodsForEmail(this.auth, email);

      const signMethods: (SignInMethod | undefined)[] =
        methods.map(mapSignInMethods);

      return signMethods.filter((m) => !!m) as SignInMethod[];
    }, email);
  }

  async createUserWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<void> {
    await handleFirebase(async () => {
      await createUserWithEmailAndPassword(this.auth, email, password);
    }, email);
  }

  async getAuthToken(): Promise<string> {
    return await handleFirebase(async () => {
      const user = this.auth.currentUser;
      if (user) return await getIdToken(user);
      else return "";
    });
  }

  async getUser(): Promise<UserInfo | undefined> {
    return await handleFirebase(async () => {
      const user = this.auth.currentUser;
      return mapFirebaseUserInfo(user);
    });
  }

  async signInWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<void> {
    return await handleFirebase(async () => {
      await signInWithEmailAndPassword(this.auth, email, password);
    });
  }

  async signInWithFacebook(): Promise<void> {
    return await handleFirebase(async () => {
      const provider = new FacebookAuthProvider();
      provider.addScope("email");
      provider.addScope("public_profile");

      warn("We're using a redirect-ONLY impl. Please implement properly.");
      await signInWithRedirect(this.auth, provider);

      // isInAppBrowser()
      //   ? await signInWithRedirect(this.auth, provider)
      //   : await signInWithPopup(this.auth, provider);
    });
  }

  async signInWithGoogle(): Promise<void> {
    return await handleFirebase(async () => {
      const provider = new GoogleAuthProvider();
      provider.addScope("https://www.googleapis.com/auth/userinfo.email");
      provider.addScope("https://www.googleapis.com/auth/userinfo.profile");

      await signInWithRedirect(this.auth, provider);
      // isInAppBrowser()
      //   ? await signInWithRedirect(this.auth, provider)
      //   : await signInWithPopup(this.auth, provider);
    });
  }

  async signInWithApple(): Promise<void> {
    return await handleFirebase(async () => {
      const provider = new OAuthProvider("apple.com");
      provider.addScope("email");
      provider.addScope("name");

      await signInWithRedirect(this.auth, provider);
      // isInAppBrowser()
      //   ? await signInWithRedirect(this.auth, provider)
      //   : await signInWithPopup(this.auth, provider);
    });
  }

  onAuthChange(listener: (userInfo: UserInfo | undefined) => void): Handle {
    return this.emitter.on("authStateChanged", (event: AuthChangedEvent) => {
      log("AUTH CHANGED", event);
      listener(event.data);
    });
  }

  onRedirect(listener: (userInfo: UserInfo | undefined) => void): Handle {
    return this.emitter.on("redirect", (ev: RedirectEvent) =>
      listener(ev.data)
    );
  }

  async logout(): Promise<void> {
    return await handleFirebase(async () => {
      log("Signing out");
      await signOut(this.auth);
    });
  }
}
