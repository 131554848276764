import React from "react";

import { Product } from "@greeter/core";
import { Receipt, ReceiptLine } from "../Receipt";

export type SummaryLine = {
  quantity: number;
  product: Product;
};

export type SummaryProps = {
  lines: ReceiptLine[];
  customTotal?: number;
};

export const Summary: React.FC<SummaryProps> = ({ lines }) => {
  return <Receipt lines={lines} />;
};
