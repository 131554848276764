import React from "react";
import { ImageAsset, Product } from "@greeter/core";
import { Card, CardContent, CardHeader, CardTitle } from "../Card";
import { classNames } from "@greeter/util";
import { HorizontalLine } from "../HorizontalLine";
import { LazyCoverImage } from "@greeter/matter";

import css from "./ExpandableTablePackageCard.module.scss";

export type ExpandableTablePackageCardProps = React.PropsWithChildren & {
  className?: string;
  style?: React.CSSProperties;
  isActive?: boolean;
  product: Product;
};

export const ExpandableTablePackageCard: React.FC<
  ExpandableTablePackageCardProps
> = ({ className = "", isActive = false, product, style }) => {
  return (
    <Card {...classNames(className, css.Card, isActive ? css.Active : "")}>
      <CardHeader className={css.CardHeader}>
        <LazyCoverImage src={product.coverAsset ? ImageAsset.findUriWithSizeOrDefault(product.coverAsset, "4x3-w512") : product.coverUrl} />
      </CardHeader>
      <CardContent className={css.CardContent}>
        <div className={css.MainContent}>
          <CardTitle size="md">{product.title}</CardTitle>
          <div style={{ position: "relative" }}>
            <div className={css.CardDescription}>
              <pre>{product.description}</pre>
            </div>
            <div className={css.Fade} />
          </div>
        </div>
        <HorizontalLine className={css.HorizontalLine} />
        <div className={css.Footer}>
          <div className={css.Price}>{product.price},-</div>
        </div>
      </CardContent>
    </Card>
  );
};
