import { OrderLine } from "./OrderLine";

export type OrderStatus =
  | "created"
  | "accepted"
  | "rejected"
  | "pending"
  | "cancelled"
  | "refunded"
  | "completed";

export type Order = {
  id: string;
  createdAt: Date;
  orderLines: OrderLine[];
  status: OrderStatus;
  total: number;
  venueId: string;
  customerId: string;
};

export type RejectedOrder = {
  rejectionMessage: string;
} & Order;

export module Order {
  export function is(self: Order, status: OrderStatus) {
    return self.status === status;
  }
}
