import React from "react";
import { HTMLAttributes } from "react";
import { classNames } from "@greeter/util";

import css from "./RoundedButton.module.scss";

export type RoundedButtonProps = HTMLAttributes<HTMLButtonElement> & {
  active: boolean;
  activeClassName?: string;
};

export const RoundedButton: React.FC<RoundedButtonProps> = ({
  activeClassName,
  active,
  className,
  ...props
}) => (
  <button
    {...props}
    {...classNames(
      className,
      css.RoundedButton,
      active && [css.Active, activeClassName],
    )}
  />
);
