import { sortBy } from "lodash";
import { Category } from "./Category";
import { Price } from "./Price";
import { ImageAsset } from "./ImageAsset";
import { Asset } from "./Asset";

export type Product = {
  id: string;
  title: string;
  subTitle: string;
  price: number;
  priority: number;
  description: string;
  coverAsset?: ImageAsset;
  active?: boolean;
  coverUrl: string;
};

export module Product {
  export function createDefault(): Product {
    return {
      id: "",
      title: "",
      subTitle: "",
      price: 0,
      priority: 0,
      description: "",
      coverUrl: "",
    };
  }

  export function sameAs(a: Product, b: Product) {
    return a.id === b.id;
  }

  export function sortByPriceAndPriorityProperties() {
    return [(p: Product) => p.price, (p: Product) => p.priority];
  }

  export function sortByPriceAndPriority(products: Product[]) {
    return sortBy(sortBy(products, p => p.price), p => p.priority);
  }
}
