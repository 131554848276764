import React, { useMemo } from "react";
import { Order, OrderLine, Product } from "@greeter/core";
import { ReceiptLine } from "./ReceiptLine";

import css from "./Receipt.module.scss";

export type ReceiptLine = {
  quantity: number;
  product: Pick<Product, "title" | "price" | "id">;
};

export type ReceiptLinesProps = {
  lines: ReceiptLine[];
};
export type ReceiptProps = React.PropsWithChildren &
  ReceiptLinesProps & {
    customTotal?: number;
  };

export function fromOrder(order: Order): ReceiptLine[] {
  return order.orderLines.map((ol: OrderLine) => {
    return {
      product: {
        title: ol.title,
        price: ol.unitPrice,
      } as ReceiptLine["product"],
      quantity: ol.quantity,
    };
  });
}

export function ReceiptLines({ lines }: ReceiptLinesProps) {
  return lines.map(({ quantity, product: p }, i: number) => {
    return (
      <ReceiptLine
        key={p.id === "" ? undefined : p.id}
        left={`${quantity} x ${p.title}`}
        right={`${p.price * quantity} kr`}
      />
    );
  });
}

export function Receipt({ lines, customTotal }: ReceiptProps) {
  const total = lines.reduce(
    (acc: number, { product, quantity }: ReceiptLine) =>
      (acc += product.price * quantity),
    0
  );
  return (
    <div>
      <h3 className={css.Title}>Priser DKKR inkl. moms</h3>
      <div className={css.Receipt}>
        <ReceiptLines lines={lines} />
        <div className={css.ReceiptHR} />
        <ReceiptLine left={"Total"} right={`${total} kr`} />
        {customTotal && (
          <>
            <ReceiptLine left="Tilbud" right={`${customTotal} kr`} />
            <ReceiptLine left="Difference" right={`${total - customTotal} kr`} />
          </>
        )}
      </div>
    </div>
  );
}
