import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { IonPage, IonContent, useIonViewDidEnter, IonIcon } from "@ionic/react";
import { classNames, Query } from "@greeter/util";
import { logger } from "@greeter/log";
import {
  Category,
  ImageAsset,
  Menu as MenuModel,
  MenuProduct,
  Product,
  TableServiceSettings,
  Venue,
} from "@greeter/core";
import {
  Cart,
  CartItem,
  cartMachine,
  CartMachineRef,
  getTotal,
  IncrementQuantityEvent,
  SetIdEvent,
  SetQuantityEvent,
} from "@greeter/commerce";

import {
  CoverImage,
  ProductsCarousel,
  QuantityControls,
  TitledSection,
  FloatingActionBar,
  FloatingActionTitle,
  FloatingActionSubTitle,
  useHiddenTabBar,
  Carousel,
  RoundedButton,
  Card,
  CardContent,
  LazyCoverImage,
  PageCover,
  LazyImage,
} from "@greeter/matter";
import { assign, createActor, raise, sendTo, setup } from "xstate";
import { useMachine, useSelector } from "@xstate/react";

import { useHistory } from "react-router-dom";
import Routes from "@greeter-guest/utility/Routes";
import { debounce, first, flatten, sortBy, throttle } from "lodash";
import {
  arrowDown,
  cellularOutline,
  cloudOfflineOutline,
} from "ionicons/icons";
import { Network } from "@capacitor/network";

import "animate.css";
import css from "./MenuPage.module.scss";
import { useThrottledValue } from "@greeter-guest/utility/Hooks";
import { any } from "@greeter/array";

const log = logger("[MenuPage]");

export type CategoryWithProducts = {
  id: string;
  name: string;
  products: MenuProduct[];
};

export function mergeCategoriesWithProducts(
  menu: MenuModel
): CategoryWithProducts[] {
  const categories: CategoryWithProducts[] = menu.categories
    .map((c) => ({
      id: c.id,
      name: c.name,
      products: menu.products.filter((p) =>
        p.categories.some((categoryId) => c.id === categoryId)
      ),
    }));

  return categories;
}

export const Price: React.FC<{ value: number }> = ({ value }) => {
  const leftover = Math.floor(value) - value;
  return leftover > 0 ? <span>{value} kr.</span> : <span>{value},- kr.</span>;
};

export type MenuHeaderProps = React.PropsWithChildren & {
  venue: Query<Venue>;
};
export const MenuHeader: React.FC<
  MenuHeaderProps & React.HTMLProps<HTMLDivElement>
> = ({ venue, ...props }) => {
  return (
    <div className={css.Header} {...props}>
      {venue.type === "done" && (
        <PageCover>
          <LazyCoverImage
            src={ImageAsset.findUriWithSizeOrDefault(
              venue.data.coverAsset,
              "16x9-w1024"
            )}
          />
        </PageCover>
      )}
      {venue.type === "done" && (
        <div className={css.VenueLogo}>
          {venue.data.logoUrl ? <img src={venue.data.logoUrl} /> : <div />}
        </div>
      )}
    </div>
  );
};

const useNetwork = () => {
  const [status, setStatus] = useState<OnlineStatus>("connected");

  useEffect(() => {
    Network.addListener("networkStatusChange", (ev) => {
      setStatus(ev.connected ? "connected" : "disconnected");
    });
  }, []);

  return status;
};

type OnlineStatus = "disconnected" | "connected";

const OnlineBar: React.FC = () => {
  const status = useNetwork();

  return status === "disconnected" ? (
    <div className={css.OnlineBar}>
      <IonIcon icon={cloudOfflineOutline} /> <span>Mistet forbindelsen...</span>
    </div>
  ) : (
    <></>
  );
};

const DisconnectedBar: React.FC = () => {
  return (
    <div className={css.DisconnectedBar}>
      <IonIcon icon={cloudOfflineOutline} />{" "}
      <span>
        <b>Mistet forbindelsen</b>...
      </span>
    </div>
  );
};

type MenuItemProps = React.PropsWithChildren & {
  product: Product;
};
const MenuItem: React.FC<MenuItemProps> = () => {
  return null;
};

type UseQuerySelectorOptions = {
  root?: Element | null | undefined;
  dependencies?: React.DependencyList;
};

function selectRoot(other?: Element | null | undefined) {
  return other ?? document;
}

const useQuerySelectorAll = (
  query: string,
  options?: UseQuerySelectorOptions
) => {
  const q = useCallback(
    () => Array.from(selectRoot(options?.root).querySelectorAll(query)),
    options?.dependencies ?? []
  );
  return q;
};

const useQuerySelector = (query: string, options?: UseQuerySelectorOptions) => {
  const q = useCallback(
    () => selectRoot(options?.root).querySelector(query),
    options?.dependencies ?? []
  );
  return q;
};

export type MenuProps = React.HTMLProps<HTMLDivElement> & {
  cart: Cart;
  menuWithCategories: CategoryWithProducts[];
  onAdd: (productId: string) => void;
  onSet: (productId: string, quantity: number) => void;
  simple?: boolean;
};

export const MenuContainer: React.FC<React.HTMLProps<HTMLDivElement>> = ({
  ...props
}) => {
  return <div className={css.MenuContainer} {...props} />;
};

export function MenuContent({
  menuWithCategories,
  cart,
  onSet,
  onAdd,
  simple,
  ...props
}: MenuProps) {
  const [render, setRender] = useState(0);

  useEffect(() => {
    const id = setInterval(() => {
      setRender(Math.random())
    }, 10_000);

    return () => clearInterval(id);
  }, []);

  return (
    <div className={css.MenuContent} {...props}>
      {menuWithCategories.filter(mwc => mwc.products.length > 0).map((cw, i) => {
        return (
          <div
            {...classNames(
              "category",
              css.Category,
              i === menuWithCategories.length - 1 && css.LastCategory
            )}
            style={{ paddingBottom: "2rem" }}
          >
            <div key={cw.id} className={css.CategoryTitle}>
              {cw.name}
            </div>
            {cw.products.map((p, j) => {
              const img = p.coverAsset?.sizes?.find(
                ImageAsset.withSize("1x1-w256")
              );
              return (
                <div
                  key={`product-${p.id}`}
                  data-testid={`menu-item-${(i + 1) * j}`}
                  {...classNames(css.MenuItem, img?.uri && css.WithImage)}
                >
                  {img?.uri && (
                    <LazyImage
                      src={img?.uri}
                      styles={{
                        container: {
                          gridArea: "i",
                          borderRadius: "10px",
                          overflow: "hidden",
                        },
                      }}
                      alt={p.title}
                    />
                  )}
                  <div className={css.MenuItemTitle}>{p.title}</div>
                  <div className={css.MenuItemDescription}>{p.description.split("\n").map(l => <p>{l}</p>)}</div>
                  <div className={css.MenuItemPrice}>
                    <Price value={MenuProduct.priceAfterPriceChanges(p)} />
                  </div>
                  <div className={css.MenuItemBtns}>
                    {!simple && !cart.exists(p.id) ? (
                      <div
                        data-testid="initial-add"
                        className={css.MenuItemAddBtn}
                        onClick={() => {
                          onAdd(p.id);
                        }}
                      >
                        Tilføj
                      </div>
                    ) : (
                      !simple && (
                        <QuantityControls
                          tight
                          size="sm"
                          quantity={cart.get(p.id)}
                          onChange={(quantity: number) => {
                            onSet(p.id, quantity);
                          }}
                        />
                      )
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

type MenuMachineContext = {
  featuredCategory?: CategoryWithProducts;
  menu: CategoryWithProducts[];
  cartRef?: CartMachineRef;
  settings?: TableServiceSettings;
  onlineStatus?: OnlineStatus;
};

type SetMenuEvent = { type: "setMenu"; data: MenuModel };
type SetSettingsEvent = { type: "setSettings"; data: TableServiceSettings };
type ValidateEvent = {
  type: "validate";
};
type RefreshEvent = {
  type: "refresh";
};
type SetOnlineStatusEvent = {
  type: "setOnlineStatus";
  data: OnlineStatus;
};

type MenuMachineEvents =
  | RefreshEvent
  | SetIdEvent
  | SetMenuEvent
  | SetSettingsEvent
  | SetQuantityEvent
  | SetOnlineStatusEvent
  | IncrementQuantityEvent
  | ValidateEvent;

type ValidState = { value: "valid"; context: MenuMachineContext };
type EmptyCartState = { value: "emptyCart"; context: MenuMachineContext };
type ClosedState = { value: "closed"; context: MenuMachineContext };
type ValidatingState = { value: "validating"; context: MenuMachineContext };
type InvalidMinPriceState = {
  value: "invalidMinPrice";
  context: MenuMachineContext;
};
type DisconnectedState = {
  value: "disconnected";
  context: MenuMachineContext;
};

function cart({ context }: { context: MenuMachineContext }) {
  return context.cartRef!;
}

type MenuMachineStates =
  | ValidState
  | EmptyCartState
  | ClosedState
  | ValidatingState
  | DisconnectedState
  | InvalidMinPriceState;

const menuMachine = setup({
  types: {} as {
    context: MenuMachineContext;
    events: MenuMachineEvents;
  },
}).createMachine({
  initial: "validating",
  context: {
    menu: [],
    onlineStatus: "connected",
  },
  entry: assign(({ spawn }) => {
    return { cartRef: spawn(cartMachine) };
  }),
  on: {
    setOnlineStatus: {
      actions: [
        assign({ onlineStatus: ({ event: ev }) => ev.data }),
        raise({ type: "validate" }),
      ],
    },
    setId: {
      actions: [sendTo(cart, ({ event: ev }) => ev)],
    },
    refresh: {
      actions: [sendTo(cart, () => ({ type: "reloadCart" }))],
    },
    setMenu: {
      actions: [
        assign(({ context: { settings }, event }) => {
          const menu = event.data;

          menu.categories = sortBy(menu.categories, (c) => c.priority);

          const categoriesWithProducts = mergeCategoriesWithProducts(menu);

          let featuredCategory: CategoryWithProducts | undefined = undefined;

          if (menu.featuredCategoryId) {
            featuredCategory = categoriesWithProducts.find(
              (c) => c.id === menu.featuredCategoryId
            );
          }

          const restOfCategories = categoriesWithProducts.filter(
            (c) => c.id !== featuredCategory?.id
          );

          return {
            menu: restOfCategories,
            featuredCategory: featuredCategory,
          };
        }),
      ],
    },
    incrementQuantity: {
      actions: [
        sendTo(cart, ({ event: ev }) => ev),
        sendTo(cart, { type: "validate" }),
      ],
    },
    setQuantity: {
      actions: [
        sendTo(cart, ({ event: ev }) => ev),
        sendTo(cart, { type: "validate" }),
      ],
    },
    setSettings: {
      actions: [assign({ settings: ({ event: ev }) => ev.data })],
    },
  },
  states: {
    validating: {
      entry: raise({ type: "validate" }),
      on: {
        validate: [
          {
            target: "disconnected",
            guard: ({ context }) => context.onlineStatus === "disconnected",
          },
          {
            target: "closed",
            guard: ({ context }) =>
              !!context.settings && context.settings.mode === "closed",
          },
          {
            target: "emptyCart",
            guard: ({ context }) =>
              context.cartRef?.getSnapshot()?.matches("empty") ?? false,
          },
          {
            target: "invalidMinPrice",
            guard: ({ context }) => {
              const cart = context.cartRef?.getSnapshot()?.context.cart;
              const products = flatten([
                ...context.menu.map((m) => m.products),
                ...(context.featuredCategory?.products ?? []),
              ]);
              if (cart) {
                const totalResult = getTotal(products, cart);

                return totalResult.total < 100;
              } else {
                return true;
              }
            },
          },
          {
            target: "valid",
            guard: ({ context }) =>
              context.cartRef?.getSnapshot()?.matches("notEmpty") ?? false,
          },
        ],
      },
    },
    disconnected: {
      on: { validate: { target: "validating" } },
    },
    invalidMinPrice: {
      on: { validate: { target: "validating" } },
    },
    emptyCart: {
      on: { validate: { target: "validating" } },
    },
    closed: {
      on: { validate: { target: "validating" } },
    },
    valid: {
      on: { validate: { target: "validating" } },
    },
  },
});

type FeaturedCategoryProps = {
  featuredCategory: CategoryWithProducts;
  isInteractive: boolean;

  onProductChange: (p: Product) => void;
  onQuantityChange: (quantity: number) => void;

  quantity: number;
};
const FeaturedCategory: React.FC<FeaturedCategoryProps> = (
  props: FeaturedCategoryProps
) => {
  return (
    <TitledSection
      title={props.featuredCategory.name}
      {...classNames("category", css.CategoryHighlight)}
      center
      data-test-id="featuredCategory"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          padding: "3rem",
        }}
      >
        <ProductsCarousel
          products={props.featuredCategory.products ?? []}
          onProductChanged={props.onProductChange}
        />
        <div style={{ display: "grid", placeItems: "center" }}>
          {props.isInteractive && (
            <QuantityControls
              onChange={props.onQuantityChange}
              quantity={props.quantity}
            />
          )}
        </div>
      </div>
    </TitledSection>
  );
};

type OverlayBackdropProps = PropsWithChildren & {
  onClick?: () => void;
};

const OverlayBackdrop: React.FC<OverlayBackdropProps> = (
  props: OverlayBackdropProps
) => {
  return <div className={css.OverlayBackdrop} {...props} />;
};

type MenuClosedSignProps = {
  featuredCategory?: CategoryWithProducts;
  onClick: () => void;
};

const MenuClosedSign: React.FC<MenuClosedSignProps> = (
  props: MenuClosedSignProps
) => {
  return (
    <OverlayBackdrop onClick={props.onClick}>
      <Card className={css.MenuClosedSign}>
        <CardContent>
          <p style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
            Du kan kun bestille {props.featuredCategory?.name ?? "Bordpakker"}{" "}
            🥳
          </p>
          <p
            style={{
              fontWeight: "bold",
              fontSize: "1.0rem",
              opacity: "0.75",
            }}
          >
            De er til gengæld rigtig gode...
          </p>
          <p
            style={{
              fontWeight: "bold",
              fontSize: "1.0rem",
            }}
          >
            Ellers kan du hoppe op i baren og bestille dine drinks
          </p>
        </CardContent>
      </Card>
    </OverlayBackdrop>
  );
};

type ConfusedBarProps = {
  onClick?: () => void;
  style?: React.CSSProperties;
  className?: string;
};

const ConfusedBar: React.FC<ConfusedBarProps> = (props: ConfusedBarProps) => {
  return (
    <div
      {...classNames("animate__animated", "animate__fadeInUp", css.ConfusedBar)}
      {...props}
    >
      <IonIcon
        icon={arrowDown}
        className="animate__animated animate__bounce animate__repeat-3"
      />{" "}
      Scroll for at se resten
    </div>
  );
};

export type MenuPageProps = React.PropsWithChildren & {
  menu: Query<MenuModel>;
  venue: Query<Venue>;
  settings: Query<TableServiceSettings>;
  tableNumber: number;
  area: string;
  mode: "non-interactive" | "interactive";
};

export const MenuPage: React.FC<MenuPageProps> = ({
  menu,
  venue,
  tableNumber,
  area,
  settings,
  mode = "interactive",
}) => {
  const history = useHistory();

  const [state, send, machine] = useMachine(menuMachine);

  useHiddenTabBar();

  const cartMachine = useSelector(machine, (state) => state.context.cartRef);
  const cartState = cartMachine?.getSnapshot();

  const [product, setProduct] = useState<Product>();
  const productQuantity = useMemo(() => {
    if (!product) return 0;

    return cartState?.context.cart.get(product.id) ?? 0;
  }, [cartState, product]);

  useEffect(() => {
    const handle = (ev) => {
      send({
        type: "setOnlineStatus",
        data: ev.connected ? "connected" : "disconnected",
      });
    };
    const unsub = Network.addListener("networkStatusChange", handle);
    return () => {
      (async () => {
        (await unsub).remove();
      })();
    };
  }, [send]);

  useEffect(() => {
    if (menu.type === "done") send({ type: "setMenu", data: menu.data });
    if (settings.type === "done" && menu.type === "done")
      send({ type: "setMenu", data: menu.data });
  }, [menu, settings, send]);

  useEffect(() => {
    if (settings.type === "done") {
      send({ type: "setSettings", data: settings.data });
      send({ type: "validate" });
    }
  }, [settings, send]);

  const venueId = venue.type === "done" ? venue.data.id : "";

  useEffect(() => {
    send({ type: "setId", data: venueId });
  }, [venue]);

  const sendIncQuantity = useCallback(
    (id: string) => {
      send({ type: "incrementQuantity", data: { productId: id } });
      send({ type: "validate" });
    },
    [send]
  );
  const sendSetQuantity = useCallback(
    (id: string, quantity: number) => {
      send({ type: "setQuantity", data: { productId: id, quantity } });
      send({ type: "validate" });
    },
    [send]
  );

  const handleQuantityChange = useCallback(
    (quantity: number) => {
      if (!product) return;

      sendSetQuantity(product.id, quantity);
    },
    [sendSetQuantity, product]
  );

  const throttledState = useThrottledValue<MenuMachineStates["value"]>(
    () => state.value as MenuMachineStates["value"],
    {
      delay: 100,
    }
  );

  useEffect(() => {
    const sub = machine.subscribe(log);
    return sub.unsubscribe;
  }, [machine]);

  useIonViewDidEnter(() => {
    send({ type: "refresh" });
  });

  const menuRef = useRef<HTMLDivElement>(null);
  const [confused, setConfused] = useState(false);
  const [distanceToMenu, setDistanceToMenu] = useState(0);
  const VISIBILITY_THRESHOLD = 100;

  useEffect(() => {
    const id = setInterval(() => {
      if (distanceToMenu < VISIBILITY_THRESHOLD) setConfused(true);
    }, 1000);

    return () => clearInterval(id);
  }, [distanceToMenu]);

  useEffect(() => {
    if (confused) setConfused(distanceToMenu < VISIBILITY_THRESHOLD);
  }, [confused, distanceToMenu]);

  /**
   * Trying out a query approach instead of a useRef approach.
   * The reason is the ever looming need to React.forwardRef your custom components
   * which is tedious and annoying.
   *
   * Instead. Simply query the elements directly and if performance is out of whack
   * optimize from there.
   */
  const queryCategoryElements = useQuerySelectorAll(".category");
  const queryCategoryBtnElements = useQuerySelectorAll(".category-btn");
  const queryCategoryCarouselElement = useQuerySelector("#category-carousel");
  const categoryCarouselElement = queryCategoryCarouselElement();

  const [nearestCategoryIndex, setNearestCategoryIndex] = useState(0);

  const fabOffset = categoryCarouselElement
    ? `calc(0.25rem + ${
        categoryCarouselElement.getBoundingClientRect().height ?? 0
      }px)`
    : "1rem";

  const handleScroll = useCallback(() => {
    if (menuRef.current) {
      setDistanceToMenu(
        Math.abs(menuRef.current.getBoundingClientRect().y - window.innerHeight)
      );
    }

    const categoryElements = queryCategoryElements();

    if (categoryElements) {
      let nearestIndex = 0;
      let nearest: Element | undefined;
      let nearestBox: DOMRect | undefined;

      for (let i = 0; i < categoryElements.length; i++) {
        const current = categoryElements[i];
        const box = current.getBoundingClientRect();

        if (
          !nearest ||
          !nearestBox ||
          Math.abs(box.y) < Math.abs(nearestBox.y)
        ) {
          nearest = current;
          nearestBox = box;
          nearestIndex = i;
        }
      }

      setNearestCategoryIndex(nearestIndex);
    }
  }, [queryCategoryElements]);

  const throttledHandleScroll = useMemo(
    () => throttle(handleScroll, 100, { leading: true }),
    [handleScroll]
  );

  const isInteractive = mode === "interactive";

  const handleScrollEnd = () => {
    // TODO: This is best solution so far.
    //       Interesting quirck. We cannot use .scrollIntoView on two elements at
    //       the same time in chrome for some reason
    queryCategoryBtnElements()[nearestCategoryIndex]?.scrollIntoView({
      behavior: "auto",
      block: "start",
      inline: "start",
    });
  };

  const handleGotoCart = () => {
    if (venue.type !== "done") return;

    history.push(
      Routes.cart.route({
        venueId: venue.data.id,
        tableNumber: tableNumber,
        area: area,
      })
    );
  };

  return (
    <IonPage key="menu">
      <IonContent
        scrollEvents
        onIonScrollEnd={handleScrollEnd}
        onIonScroll={throttledHandleScroll}
      >
        <div className={css.Container}>
          <div className={css.HeaderContainer}>
            <div className={css.Header}>
              <PageCover>
                <LazyCoverImage
                  src={
                    venue.type === "done" &&
                    ImageAsset.findUriWithSizeOrDefault(
                      venue.data.coverAsset,
                      "16x9-w1024"
                    )
                  }
                />
              </PageCover>
              <div className={css.VenueLogo}>
                <LazyImage
                  alt="Venue logo"
                  src={venue.type === "done" && venue.data.logoUrl}
                />
              </div>
            </div>
            {state.context.featuredCategory && (
              <FeaturedCategory
                featuredCategory={state.context.featuredCategory}
                isInteractive={isInteractive}
                onProductChange={setProduct}
                onQuantityChange={handleQuantityChange}
                quantity={productQuantity}
              />
            )}
          </div>
          <div ref={menuRef} className={css.Menu} data-test-id="menu">
            {cartState?.context.cart && (
              <MenuContainer>
                <MenuContent
                  {...classNames(
                    settings.type === "done" &&
                      settings.data.mode === "featured" &&
                      css.ClampHeight
                  )}
                  onSet={sendSetQuantity}
                  onAdd={sendIncQuantity}
                  menuWithCategories={state.context.menu}
                  cart={cartState.context.cart}
                  simple={!isInteractive}
                />
                {settings.type === "done" &&
                  settings.data.mode === "featured" &&
                  isInteractive && (
                    <MenuClosedSign
                      onClick={() => {
                        first(queryCategoryElements())?.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                          inline: "center",
                        });
                      }}
                    />
                  )}
              </MenuContainer>
            )}
          </div>
        </div>
      </IonContent>
      {isInteractive && throttledState === "valid" ? (
        <FloatingActionBar
          type="gradient"
          style={{ bottom: fabOffset }}
          onClick={handleGotoCart}
        >
          <FloatingActionTitle style={{ fontSize: "1.1rem" }}>
            Tryk for at bestille
          </FloatingActionTitle>
        </FloatingActionBar>
      ) : isInteractive && throttledState === "invalidMinPrice" ? (
        <FloatingActionBar type="warning" style={{ bottom: fabOffset }}>
          <FloatingActionTitle style={{ alignSelf: "end" }}>
            Du skal bestille for min. 100 kr.
          </FloatingActionTitle>
          <FloatingActionSubTitle style={{ alignSelf: "start" }}>
            Vælg nogle flere drinks, køb en til din ven, drik to, let's go
          </FloatingActionSubTitle>
        </FloatingActionBar>
      ) : isInteractive && throttledState === "closed" ? (
        <FloatingActionBar type="warning" style={{ bottom: fabOffset }}>
          <FloatingActionTitle style={{ alignSelf: "end" }}>
            Bordbestilling er lukket for iaften
          </FloatingActionTitle>
          <FloatingActionSubTitle style={{ alignSelf: "start" }}>
            Du kan stadig gå op i baren og bestille dine drinks
          </FloatingActionSubTitle>
        </FloatingActionBar>
      ) : (
        confused && (
          <ConfusedBar
            style={{ bottom: fabOffset }}
            onClick={() => {
              menuRef.current?.scrollIntoView({ behavior: "smooth" });
            }}
          />
        )
      )}
      {throttledState === "disconnected" && <DisconnectedBar />}

      {menu.type === "done" && (
        <div
          id="category-carousel"
          {...classNames(
            "animate__animated",
            "animate__fadeInUp",
            css.CategoryBar
          )}
        >
          <Carousel fades snap="start">
            {[
              // Puts the featured category first, always
              ...menu.data.categories.filter(
                (c) => c.id === menu.data.featuredCategoryId
              ),
              ...menu.data.categories.filter(
                (c) => c.id !== menu.data.featuredCategoryId
              ),
            ].map((c, i) => {
              return (
                <RoundedButton
                  className="category-btn"
                  active={nearestCategoryIndex === i}
                  style={{ fontSize: "1.1rem", padding: ".75rem 1.5rem" }}
                  onClick={() => {
                    const element = queryCategoryElements()[i];
                    element.scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  {c.name}
                </RoundedButton>
              );
            })}
          </Carousel>
        </div>
      )}
    </IonPage>
  );
};
