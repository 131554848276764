import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const resources = {
    en: {
        translation: {
            test: "TESTING",
        },
    },
    da: {
        translation: {
            test: "TESTING",
            week: {
                monday: "mandag",
                tuesday: "tirsdag",
                wednesday: "onsdag",
                thursday: "torsdag",
                friday: "fredag",
                saturday: "lørdag",
                sunday: "søndag",
            },
            month: {
                january: "januar",
                february: "februar",
                march: "marts",
                april: "april",
                may: "maj",
                june: "juni",
                july: "juli",
                august: "august",
                september: "september",
                october: "oktober",
                november: "november",
                december: "december",
            },
        },
    },
};

i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    // .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources: resources,
        lng: "da",
        fallbackLng: "da",
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        // backend: {
        //     loadPath: "/locales/da/translation.json"
        // },
    });

export default i18n;
