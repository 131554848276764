import React, { useMemo } from "react";

import { IonIcon } from "@ionic/react";
import { addCircleOutline, removeCircleOutline } from "ionicons/icons";
import { clamp } from "lodash";
import { Button } from "../Button";

import css from "./QuantityControls.module.scss";
import { ReceiptLine } from "../Receipt";
import { Product } from "@greeter/core";
import { classNames } from "@greeter/util";

export const useQuantity = (orderLines: ReceiptLine[], product: Product) =>
  useMemo(() => {
    return orderLines.find((ol) => ol.product.id === product.id)?.quantity ?? 0;
  }, [orderLines, product]);

export const upsertQuantity = (
  orderLines: ReceiptLine[],
  product: Product,
  quantity: number
) => {
  const existingOrderLine = orderLines.find(
    (ol) => product && ol.product?.id === product?.id
  );

  if (existingOrderLine) {
    const updated = [
      ...orderLines.filter(
        (ol) =>
          ol.product.id !== existingOrderLine.product.id && ol.quantity > 0
      ),
      { ...existingOrderLine, quantity } as ReceiptLine,
    ];
    return updated;
  }

  if (product)
    return [
      ...orderLines.filter((ol) => !!ol.product && ol.quantity > 0),
      { product: product, quantity: 1 } as ReceiptLine,
    ];

  return orderLines;
};

type QuantityButtonProps = {
  type: "sub" | "add";
  size: "lg" | "sm";
  disabled?: boolean;
  onClick?: () => void;
};

const QuantityButton: React.FC<QuantityButtonProps> = ({
  type,
  size = "lg",
  disabled,
  onClick,
  ...props
}) => {
  return (
    <Button
      {...classNames(css.QuantityButton, size === "lg" ? css.Large : css.Small)}
      onClick={onClick}
      style={{ backgroundColor: "rgba(0,0,0,0)", padding: "0" }}
      {...props}
    >
      {type === "add" ? (
        <>
          <div className={css.Add} />
          <div className={css.Add} />
        </>
      ) : (
        <div className={css.Sub} />
      )}
    </Button>
  );
};

export type QuantityControlsProps = {
  quantity: number;
  onChange: (quantity: number) => void;
  tight?: boolean;
  size?: "sm" | "lg";
  style?: React.CSSProperties;
};

export const QuantityControls: React.FC<QuantityControlsProps> = ({
  quantity,
  onChange,
  tight = false,
  size = "lg",
  style,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: tight ? "0.5rem" : "1rem",
        alignItems: "center",
        color: "white",
        fontSize: size === "lg" ? "1.25rem" : "1rem",
        ...style,
      }}
    >
      <QuantityButton
        data-testid="quantity-sub"
        type="sub"
        size={size}
        onClick={() => onChange(clamp(quantity - 1, 0, Number.MAX_VALUE))}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: quantity > 0 ? "var(--gm-color-primary-highlight)" : "white",
        }}
      >
        <div style={{ fontWeight: "bold" }}>{quantity}</div>
        <div>stk.</div>
      </div>
      <QuantityButton
        data-testid="quantity-add"
        type="add"
        size={size}
        onClick={() => onChange(clamp(quantity + 1, 0, Number.MAX_VALUE))}
      />
    </div>
  );
};
