import { addDays, differenceInDays, isAfter, isSameDay } from "date-fns";
import { MusicGenre } from "./MusicGenre";
import { Theme } from "./Theme";
import { DateFactory, DatePeriod, TimeOfDay } from "@greeter/date";
import { Address } from "./Address";
import { WeeklyOpeningHours } from "./WeeklyOpeningHours";
import { ImageAsset } from "./ImageAsset";
import { SpecialOpeningHours } from "./SpecialOpeningHour";

export type Venue = {
  id: string;
  name: string;
  description: string;
  address: Address;
  logoUrl: string;
  logoAsset: ImageAsset;
  coverUrl: string;
  coverAsset: ImageAsset;
  cvr: string;
  verified: boolean;
  dressCode: string;
  launchDate: Date;
  openingHours: WeeklyOpeningHours;
  arrivalOpeningHours: WeeklyOpeningHours;
  bookableOpeningHours: WeeklyOpeningHours;
  musicGenres: MusicGenre[];
  themes: Theme[];
};

export module Venue {
  export function createDefault(): Venue {
    return {
      id: "Placeholder",
      name: "Greeter Venue",
      description: "Dette er et venue på Greeter",
      address: Address.createDefault(),
      logoUrl: "",
      logoAsset: {
        id: "",
        name: "",
        root: "",
        path: "",
        uri: "",
        sizes: [],
      },
      coverUrl: "",
      coverAsset: {
        path: "",
        uri: "",
        id: "",
        name: "",
        root: "",
        sizes: [],
      },
      cvr: "12345678",
      verified: true,
      dressCode: "Ingen",
      launchDate: DateFactory.create(),
      openingHours: new WeeklyOpeningHours(),
      arrivalOpeningHours: new WeeklyOpeningHours(),
      bookableOpeningHours: new WeeklyOpeningHours(),
      musicGenres: [],
      themes: [],
    };
  }

  export function isOpenToday(self: Venue) {
    if (!isLaterThanLaunchDate(self)) return false;

    const now = DateFactory.create();
    const nowAsDay = now.getDay();
    const openToday = self.openingHours.get(nowAsDay);
    return !!openToday;
  }

  export function isOpenNow(self: Venue) {
    if (!isLaterThanLaunchDate(self)) return false;

    const now = DateFactory.create();
    const nowAsDay = now.getDay();
    const todayOpeningHours = self.openingHours.get(nowAsDay);

    if (todayOpeningHours) {
      return TimeOfDay.fromDate(now).isWithin(
        todayOpeningHours.from,
        todayOpeningHours.to
      );
    } else {
      return false;
    }
  }

  export function isLaterThanLaunchDate(self: Venue) {
    const now = DateFactory.create();
    return isAfter(now, self.launchDate);
  }

  export type CreateDatePeriodsFromOpeningHoursArgs = {
    openingHours: WeeklyOpeningHours;
    specialOpeningHours: Array<SpecialOpeningHours>;
    range: number;
    now: Date;
  };

  /**
   * Creates DatePeriods for opening hours with settable range and now anchor.
   *
   * TODO: Create a generator version?
   */
  export function createDatePeriodsFromOpeningHours(
    args: CreateDatePeriodsFromOpeningHoursArgs
  ): Array<DatePeriod> {
    const datePeriods: Array<DatePeriod> = [];
    const now = args.now;
    const openingHours = args.openingHours;

    for (let i = 0, l = args.range; i < l; i++) {
      const date = addDays(now, i);

      const specialOpeningHours = args.specialOpeningHours.find((soh) =>
        isSameDay(date, soh.period.from)
      );

      if (specialOpeningHours) {
        datePeriods.push(specialOpeningHours.period);

        const skipForward = differenceInDays(
          specialOpeningHours.period.to,
          specialOpeningHours.period.from
        );
        // In case of multiday span, if that ever happens,
        // should result in 0 on days less than 24 hours
        i += skipForward;
        continue;
      }

      const openingHoursForDate = openingHours.get(date.getDay() as Day);
      if (!openingHoursForDate || !openingHoursForDate.isOpen) {
        continue;
      }
      const period = DatePeriod.fromTimePeriod(
        date,
        openingHoursForDate.from,
        openingHoursForDate.to
      );
      datePeriods.push(period);
    }

    return datePeriods;
  }

  export type CreateOpeningHoursDatePeriodForDateArgs = {
    targetDate: Date;
    openingHours: WeeklyOpeningHours;
    specialOpeningHours: Array<SpecialOpeningHours>;
  };
  export function createOpeningHoursDatePeriodForDate(
    args: CreateOpeningHoursDatePeriodForDateArgs
  ): DatePeriod | undefined {
    const datePeriod = createDatePeriodsFromOpeningHours({
      specialOpeningHours: args.specialOpeningHours,
      openingHours: args.openingHours,
      now: args.targetDate,
      range: 10,
    }).find((dp) => isSameDay(dp.from, args.targetDate));
    return datePeriod;
  }
}
