import { useEffect, useMemo, useState } from "react";
import { Product } from "@greeter/core";
import { difference } from "lodash";
import { ISubscribable, Signal } from "@greeter/event";
import { logger, warner } from "@greeter/log";

const name = "[Cart]";
const log = logger(name);
const warn = warner(name);

export type Quantity = number;
export type ProductId = string;
export type CartItem = [ProductId, Quantity];

export interface ICart {
  add(itemId: string): Cart;
  set(itemId: string, amount: number): Cart;
  get(itemId: string): number;
  sub(itemId: string): Cart;
  empty(): boolean;
  clear(): Cart;
  exists(itemId: string): boolean;
  remove(itemId: string): Cart;
  /**
   * Synchronizes the cart with the ids put in.
   *
   * Removes the ids not in the sync array.
   */
  sync(itemIds: string[]): void;
  entries(): CartItem[];
}

function cartId(id?: string) {
  return `greeter_cart${id ? `_${id}` : ""}`;
}

export interface IPersistable {
  save(): void;
  load(): void;
}

export type CartConstructor = {
  id?: string;
}

export class Cart implements ICart, ISubscribable, IPersistable {
  private map: Map<string, number> = new Map<string, number>();
  private signal: Signal = new Signal();
  public id?: string;

  constructor(opts?: CartConstructor) {
    this.id = opts?.id;
  }

  subscribe(func: () => void): () => void {
    return this.signal.subscribe(func);
  }

  add(itemId: string) {
    const item = this.map.get(itemId);
    if (item) {
      this.map.set(itemId, item + 1);
    } else {
      this.map.set(itemId, 1);
    }
    this.signal.emit();

    return this;
  }

  set(itemId: string, amount: number) {
    if (amount <= 0) {
      this.map.delete(itemId);
    } else {
      this.map.set(itemId, amount);
    }

    this.signal.emit();

    return this;
  }

  sub(itemId: string) {
    const item = this.map.get(itemId);

    if (item === 1) {
      this.map.delete(itemId);
    } else if (item) {
      this.map.set(itemId, item - 1);
    }
    this.signal.emit();

    return this;
  }

  remove(itemId: string) {
    this.map.delete(itemId);
    this.signal.emit();

    return this;
  }

  get(itemId: string): number {
    return this.map.get(itemId) ?? 0;
  }

  exists(itemId: string): boolean {
    return !!this.map.get(itemId);
  }

  entries() {
    return [...this.map.entries()];
  }

  sync(itemIds: string[]) {
    const keys = [...this.map.keys()];
    const removeKeys = difference(keys, itemIds);

    warn("Found keys to remove", removeKeys);

    removeKeys.forEach(this.map.delete.bind(this.map));
    this.save();

    return this;
  }

  empty() {
    const entries = this.entries();

    if (entries.length === 0) return true;

    let empty = true;

    for (const [, quantity] of entries) {
      if (quantity > 0) {
        empty = false;
        break;
      }
    }

    return empty;
  }

  save() {
    log("Saving cart to localStorage");
    localStorage.setItem(cartId(this.id), JSON.stringify(this.entries()));
  }

  load() {
    const storedCart = localStorage.getItem(cartId(this.id));
    if (storedCart) {
      try {
        const parsed: [string, number][] = JSON.parse(storedCart);

        parsed.forEach(([productId, quantity]) => {
          this.set(productId, quantity);
        });
      } catch {
        console.warn(
          "Was not able to deserialize the cart, resetting the entry."
        );
        localStorage.removeItem("greeter_cart");
        console.warn("Cart reset.");
      }
    }
  }

  clear() {
    this.map.clear();
    this.signal.emit();

    return this;
  }

  static load(opts?: CartConstructor): Cart {
    const cart = new Cart(opts);
    cart.load();

    return cart;
  }

  toJSON() {
    return JSON.stringify(this.entries());
  }
}

export const useCart = () => {
  const cart = useMemo(() => new Cart(), []);
  const [entries, setEntries] = useState<CartItem[]>([]);

  useEffect(() => {
    return cart.subscribe(() => {
      setEntries(cart.entries());
    });
  }, [cart]);

  return [entries, cart];
};

export const getProductId = (item: CartItem) => item[0];
export const getQuantity = (item: CartItem) => item[1];

export const areCartItemsEqual = (a?: CartItem, b?: CartItem) =>
  a &&
  b &&
  getProductId(a) === getProductId(b) &&
  getQuantity(a) === getQuantity(b);

export type TotalLine = {
  productId: string;
  total: number;
};
export type TotalResult = {
  lineTotals: TotalLine[];
  total: number;
};
export const getTotal = (products: Product[], cart: Cart): TotalResult => {
  const cartItems = cart.entries();

  const totalLines = products
    .filter((p) => cartItems.some(([productId]) => productId === p.id))
    .map((p) => {
      let result = { product: p, quantity: 0 };
      const [, quantity] = cartItems.find(
        ([productId]) => p.id === productId
      ) ?? ["", 0];
      if (quantity) result.quantity = quantity;
      return result;
    })
    .map(({ product, quantity }) => ({
      product: product,
      total: product.price * quantity,
    }));

  const total = totalLines.reduce((acc, curr) => acc + curr.total, 0);

  const lineTotals = totalLines.map((tl) => ({
    productId: tl.product.id,
    total: tl.total,
  }));

  return { lineTotals, total };
};
