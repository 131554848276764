import { FailedToParseError } from "./FailedToParseError";
import { addDays, millisecondsToMinutes, minutesToMilliseconds, set } from "date-fns";
import { Day } from "./Day";
import { TimeOfDay } from "./TimeOfDay";
import { DateFactory } from "./DateFactory";
import { Month } from "./Month";

/**
 * Copies itself into a new instance with the desired TimeOfDay.
 * @param timeOfDay
 */
export function withTimeOfDay(date: Date, timeOfDay: TimeOfDay | string) {
  const tod: TimeOfDay =
    typeof timeOfDay === "string" ? TimeOfDay.parse(timeOfDay) : timeOfDay;

  return set(date, {
    hours: tod.hours,
    minutes: tod.minutes,
    seconds: tod.seconds,
    milliseconds: 0,
  });
}

export function withUTCTimeOfDay(date: Date, timeOfDay: TimeOfDay) {
  const dateCopy = DateFactory.create(date);

  dateCopy.setUTCHours(timeOfDay.hours);
  dateCopy.setUTCMinutes(timeOfDay.minutes);
  dateCopy.setUTCSeconds(timeOfDay.seconds);
  dateCopy.setUTCMilliseconds(0);

  return dateCopy;
}

export function fromTimeOfDay(timeOfDay: TimeOfDay, utc: boolean = false) {
  return utc
    ? withUTCTimeOfDay(DateFactory.create(), timeOfDay)
    : withTimeOfDay(DateFactory.create(), timeOfDay);
}

export function resetTimeOfDay(date: Date, utc: boolean = false) {
  const midnight = TimeOfDay.parse("00:00:00");
  return utc ? withUTCTimeOfDay(date, midnight) : withTimeOfDay(date, midnight);
}

export type DayOfWeek =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";

export function convertToDay(value: DayOfWeek) {
  if (
    value === undefined ||
    (value as string) === "" ||
    typeof value !== "string"
  ) {
    throw Error(`Failed to convert to day. ${value} is invalid`);
  }
  switch (value.toLowerCase()) {
    case "monday":
      return Day.Monday;
    case "tuesday":
      return Day.Tuesday;
    case "wednesday":
      return Day.Wednesday;
    case "thursday":
      return Day.Thursday;
    case "friday":
      return Day.Friday;
    case "saturday":
      return Day.Saturday;
    case "sunday":
      return Day.Sunday;
    default:
      throw Error(`Failed to convert to day. ${value} is invalid`);
  }
}

export function convertDayToString(value: number) {
  switch (value) {
    case Day.Monday:
      return "monday";
    case Day.Tuesday:
      return "tuesday";
    case Day.Wednesday:
      return "wednesday";
    case Day.Thursday:
      return "thursday";
    case Day.Friday:
      return "friday";
    case Day.Saturday:
      return "saturday";
    case Day.Sunday:
      return "sunday";
    default:
      throw new FailedToParseError(value, "0..6");
  }
}

export function getDaysUntilNextDay(date: Date, nextWeekDay: Day): number {
  return (nextWeekDay - date.getDay() + 7) % 7;
}

export function getDateOfNextDay(date: Date, nextWeekDay: Day): Date {
  return addDays(new Date(date), getDaysUntilNextDay(date, nextWeekDay));
}

export function toDatetimeLocal(date: Date) {
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, "0");
  const d = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  return `${year}-${month}-${d}T${hours}:${minutes}:${seconds}`;
}

// const datetimeLocalRegex = /([0-9]{4})-([0-9]{2})-/;

export function fromDatetimeLocal(date: string) {
  return new Date(Date.parse(date));
}

export const dateFormatter = new Intl.DateTimeFormat("da-DK", {
  month: "short",
  day: "numeric",
  year: "numeric",
});
export const monthFormatter = new Intl.DateTimeFormat("da-DK", {
  month: "short",
});

export function getDay(date: Date) {
  return date.getDay() as Day;
}

export function toISODateString(date: Date) {
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const dayOfMonth = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${dayOfMonth}`;
}

export function toISOString(date: Date) {
  return date.toISOString();
}

export function toLocalISOString(date: Date) {
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const ms = date.getMilliseconds().toString().padStart(4, "0");
  const offset = date.getTimezoneOffset();

  return `${toISODateString(
    date
  )}T${hours}:${minutes}:${seconds}.${ms}+${offset}`;
}

export function mergeDates(date: Date, time: Date) {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    time.getHours(),
    time.getMinutes(),
    time.getSeconds(),
    time.getMilliseconds()
  );
}

export function toDateString(date: Date) {
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, "0");
  const d = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${d}`;
}

export function toDateOnlyString(date: Date) {
  return toDateString(date);
}

export function midnight(date?: Date) {
  return set(date ?? new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
}


/**
 * I made this to combat the strange behaviour of date-fns
 * since if you try to round 20:55 to 21:00 it will go to 21:30 instead
 * whereas if you try to round 21:00 it would result in 21:00.
 *
 * This is not what we want. We simply want the nearest  minutes.
 */
export function roundToNearestMinutes(date: Date, nearestTo: number) {
  // Reduce time to minutes, makes it easier to round to nearest minutes
  const minutes = millisecondsToMinutes(date.getTime());

  // We want to round to 30 minutes, so we count how many 30 minutes the
  // current result has.
  //
  // We use fractions here to determine the next 30 minutes
  // since if we get a fractional value like 3.2 we've essentially between 1,5 hours and 2 hours.
  // 
  // We then round up the fraction, that way we should now be able to determine
  // what the next 30 minutes is, so that would result in 2 hours in the example case
  const fraction = Math.ceil(minutes / nearestTo);

  // Finally we create a new date based on the fraction where we convert the
  // fraction we've deduce to an actual new date that align with the 30 minutes mark.
  const newDate = new Date(fraction * minutesToMilliseconds(30));

  return newDate;
}
