import { toSearchParams } from "./url";

export class Query {
  name: string;
  val: string;

  constructor(name: string, val: string) {
    this.name = name;
    this.val = val;
  }

  toString() {
    return `${this.name}=${this.val}`;
  }
}

export class UrlBuilder {
  paths: string[] = [];
  queries: Query[] = [];
  baseUrl: URL;

  constructor(baseUrl: URL) {
    this.baseUrl = baseUrl;
  }

  addPath(path: string) {
    this.paths.push(path);
    return this;
  }

  addQuery(name: string, val: any) {
    if (val) this.queries.push(new Query(name, val.toString()));
    return this;
  }

  addQueries<T>(obj: T) {
    const params = toSearchParams(obj);
    params.forEach((val, key) => this.queries.push(new Query(key, val)));

    return this;
  }

  build() {
    const pathSegment =
      this.paths.length > 0
        ? `${
            !this.baseUrl.toString().endsWith("/") ? "/" : ""
          }${this.paths.join("/")}`
        : "";

    const querySegment =
      this.queries.length > 0 ? `?${this.queries.join("&")}` : "";

    return `${this.baseUrl.toString()}${pathSegment}${querySegment}`;
  }
}
