import {
  FloorPlan,
  GreeterEvent,
  Product,
  Venue,
  TableId,
  BookingSettings,
  LockedTable,
  Menu,
  TableServiceSettings,
  Booking,
  SpecialOpeningHours,
} from "@greeter/core";
import {
  IGuestApi,
  PagedByVenueRequest,
  PagedLocationRequest,
} from "@greeter/api";
import { partial } from "lodash";
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { useDefaultGuestApi } from "./api";
import { useAuth } from "@greeter/matter";
import { DatePeriod, TimeOfDay } from "@greeter/date";
import { mapQuery } from "@greeter/util";
import { useMemo } from "react";
import { Query } from "@greeter/query";

export type Loadable<T> =
  | { status: "loading" }
  | { status: "error"; error: string }
  | { status: "success"; data: T };

// Working example for using Loadable with query result
// const d = match(productsByVenueQuery)
//     .with({ isLoading: true }, (q) => ({ status: "loading" } as OtherLoadable<Product[]>))
//     .with({ isError: true }, q => ({status: "error", error: "Failed to fetch products." } as OtherLoadable<Product[]>))
//     .with({ isSuccess: true }, q => ({ status: "success", data: q.data } as OtherLoadable<Product[]>) )
//     .run();

function useSimpleQuery<T>(reactQuery: UseQueryResult<T, Error>): Query<T> {
  return useMemo(() => Query.mapQuery(reactQuery), [reactQuery]);
}

const lockedTableKeys = {
  get: (venueId?: string, date?: Date) => [venueId, date],
};

export const useGreeterEventQuery = (
  id: string,
  options?: UseQueryOptions<GreeterEvent, Error, GreeterEvent, string[]>
) => {
  const proxy = useDefaultGuestApi();

  return useQuery(
    ["greeterEvent", id],
    partial(proxy.fetchGreeterEvent.bind(proxy), id),
    options
  );
};

export const useGreeterEventsQuery = (
  args: PagedLocationRequest,
  options?: UseQueryOptions<GreeterEvent[], Error, GreeterEvent[], string[]>
) => {
  const proxy = useDefaultGuestApi();

  return useQuery(
    ["greeterEvents", "upcoming"],
    partial(proxy.fetchUpcomingGreeterEvents.bind(proxy), args),
    options
  );
};

export const useGreeterEventsForVenueQuery = (
  args: PagedByVenueRequest,
  options?:
    | UseQueryOptions<GreeterEvent[], Error, GreeterEvent[], any[]>
    | undefined
) => {
  const proxy = useDefaultGuestApi();

  return useQuery(
    ["greeterEvents", args.venueId],
    partial(proxy.fetchUpcomingGreeterEventsForVenue.bind(proxy), args),
    options
  );
};

export const useProductsByVenueQuery = (
  venueId?: string,
  options?: UseQueryOptions<Product[], Error, Product[], string[]> | undefined
) => {
  const proxy = useDefaultGuestApi();

  return useQuery(
    ["products", venueId ?? ""],
    partial(proxy.fetchProductsByVenue.bind(proxy), venueId!),
    { enabled: !!venueId, ...options }
  );
};

export function useSpecialOpeningHoursQuery(
  venueId?: string,
  options?:
    | UseQueryOptions<
        SpecialOpeningHours[],
        Error,
        SpecialOpeningHours[],
        string[]
      >
    | undefined
) {
  const api = useDefaultGuestApi();

  const q = useQuery(
    ["venue", venueId ?? "", "specialOpeningHours"],
    async () => {
      if (!!venueId) return await api.fetchSpecialOpeningHours(venueId);
      else return [];
    },
    { enabled: !!venueId, ...options }
  );
  const simple = useSimpleQuery(q);

  return simple;
}

export const useVenueQuery = (
  venueId?: string,
  options?: UseQueryOptions<Venue, Error, Venue, any[]> | undefined
) => {
  const api = useDefaultGuestApi();
  const _options = useMemo(
    () => ({
      enabled: !!venueId,
      ...options,
    }),
    [options, venueId]
  );
  return useQuery(
    ["venue", venueId],
    partial(api.fetchVenue.bind(api), venueId ?? ""),
    _options
  );
};

export const useFloorPlanQuery = (
  venueId?: string,
  options?: UseQueryOptions<FloorPlan, Error, FloorPlan, string[]>
) => {
  const proxy = useDefaultGuestApi();
  return useQuery(
    ["floorplan", venueId ?? ""],
    partial(proxy.fetchFloorPlan.bind(proxy), venueId!),
    { enabled: !!venueId, ...options }
  );
};

const dateFormatter = Intl.DateTimeFormat("da-DK", {
  year: "numeric",
  day: "numeric",
  month: "numeric",
});

export const useBookedTablesQuery = (
  venueId?: string,
  selectedDate?: Date,
  options?:
    | UseQueryOptions<TableId[], Error, TableId[], (string | undefined)[]>
    | undefined
) => {
  const api = useDefaultGuestApi();

  return useQuery(
    [
      "bookedTables",
      venueId,
      selectedDate && dateFormatter.format(selectedDate),
    ],
    partial(api.fetchBookedTables.bind(api), venueId!, selectedDate!),
    { enabled: !!venueId && !!selectedDate, refetchInterval: 2000, ...options }
  );
};

export const useLockedTablesQuery = (
  venueId?: string,
  date?: Date,
  options?: UseQueryOptions<LockedTable[], Error, LockedTable[], any[]>
) => {
  const api = useDefaultGuestApi();

  const q = useQuery(
    lockedTableKeys.get(venueId, date),
    async () => {
      if (venueId && date) return await api.fetchLockedTables(venueId, date);
      return [];
    },
    {
      enabled: !!venueId && !!date,
      refetchInterval: 2000,
      ...options,
    }
  );

  const simpleQuery = useMemo(() => mapQuery(q), [q]);

  return simpleQuery;
};

export type UseBookingsQueryArgs = {
  period?: DatePeriod;
};
export const useBookingsQuery = (
  api: IGuestApi,
  args: UseBookingsQueryArgs,
  options?: UseQueryOptions<Booking[], Error, Booking[], any[]>
) => {
  const { isLoggedIn } = useAuth();
  const client = useQueryClient();
  return useQuery(
    ["bookings", args, isLoggedIn],
    partial(api.fetchBookings.bind(api)),
    {
      enabled: isLoggedIn,
      onSuccess: (bookings) => {
        bookings.forEach((b) => client.setQueryData(["bookings", b.id], b));
      },
      ...options,
    }
  );
};

const defaultBookingSettings: BookingSettings = {
  buffer: TimeOfDay.parse("01:00:00"),
};

export const useBookingSettingsQuery = (
  api: IGuestApi,
  venueId?: string,
  options?:
    | UseQueryOptions<BookingSettings, Error, BookingSettings, any[]>
    | undefined
) => {
  return useQuery(
    ["bookingSettings", venueId],
    async () => {
      if (venueId) return await api.fetchBookingSettings(venueId);
      else return defaultBookingSettings;
    },
    {
      // Abusing TimeOfDay as an interval
      placeholderData: defaultBookingSettings,
      ...options,
    }
  );
};

export const useMenuQuery = (
  api: IGuestApi,
  venueId?: string,
  options?: UseQueryOptions<Menu, Error, Menu, any[]>
) => {
  return useQuery(
    ["menu", venueId],
    partial(api.fetchMenu.bind(api), venueId ?? ""),
    { ...options }
  );
};

export const useTableServiceSettingsQuery = (
  api: IGuestApi,
  venueId?: string,
  options?:
    | Omit<
        UseQueryOptions<
          TableServiceSettings,
          Error,
          TableServiceSettings,
          (string | undefined)[]
        >,
        "queryKey" | "queryFn"
      >
    | undefined
) => {
  return useQuery(
    ["tableServiceSettings", venueId],
    async () => {
      if (venueId) return await api.fetchTableServiceSettings(venueId);

      return { mode: "open" } as TableServiceSettings;
    },
    { placeholderData: { mode: "open" }, ...options }
  );
};
