import React, { useMemo } from "react";

import { useDefaultGuestApi } from "@greeter-guest/api/api";
import { useMenuQuery, useTableServiceSettingsQuery } from "@greeter-guest/api/QueryHooks";
import { useVenueQuery } from "@greeter/guest-api-hooks";
import { mapQuery } from "@greeter/util";
import { getNumber, getString, useSearchParams } from "@greeter/url";
import { MenuPage } from "./MenuPage";

function parseMode(v: string | undefined): ("interactive" | "non-interactive") {
  switch(v) { 
    case "non-interactive": // fallthrough
    case "interactive": 
      return v;
    default: 
      return "interactive";
  }
}

export const MenuPageApiHandler: React.FC = () => {
  const api = useDefaultGuestApi();
  const queries = useSearchParams();
  const venueId = getString(queries, "venueId");
  const view = parseMode(getString(queries, "mode"));
  const menuQuery = useMenuQuery(api, venueId);
  const venueQuery = useVenueQuery(api, venueId ?? "");
  const settingsQuery = useTableServiceSettingsQuery(api, venueId);

  const tableNumber = getNumber(queries, "tableNumber");
  const area = queries.get("area");

  const menu = useMemo(() => mapQuery(menuQuery), [menuQuery]);
  const venue = useMemo(() => mapQuery(venueQuery), [venueQuery]);
  const settings = useMemo(() => mapQuery(settingsQuery), [settingsQuery]);

  return (
    <MenuPage
      mode={view}
      menu={menu}
      venue={venue}
      area={area ?? "Fantasi"}
      tableNumber={tableNumber ?? 0}
      settings={settings}
    />
  );
};
