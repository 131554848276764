import { Table } from "./Table";
import { DateFactory, DatePeriod, Month } from "@greeter/date";
import { isBefore } from "date-fns";
import { Order } from "./Order";

export type Year = number;

const NotFinishedBookingStatus = [
  "created",
  "pending",
  "accepted",
  "inProgress",
] as const;

const FinishedBookingStatus = ["completed", "rejected"] as const;

export const AllBookingStatus = [
  ...NotFinishedBookingStatus,
  ...FinishedBookingStatus,
] as const;

export type BookingStatus = typeof AllBookingStatus[number];

export type ContextualBookingStatus = BookingStatus | "missed" | "overdue";


export type CreatedBy = "venueAdmin" | "customer";
export type Booking = {
  id: string;
  createdAt: Date;
  groupSize: number;
  period: DatePeriod;
  tables: Map<string, Table[]>;
  status: BookingStatus;
  order?: Order;
  customerId: string;
  venueId: string;
  createdBy: CreatedBy;
  comment: string;
};

export type RejectedBooking = Booking & {
  rejectionMessage: string;
};

export module Booking {
  export function is(
    self: Booking,
    status: ContextualBookingStatus,
    now: Date = DateFactory.create()
  ) {
    return getStatus(self, now) === status;
  }

  export function setStatus(self: Booking, status: BookingStatus) {
    return { ...self, status: status };
  }

  export function getStatus(
    self: Booking,
    date: Date = DateFactory.create()
  ): ContextualBookingStatus {
    const now = date;

    const isPending = self.status === "pending";
    const isOverdue = isBefore(self.period.from, now) && isPending;

    return isOverdue ? "overdue" : self.status;
  }

  export function groupByYear(orders: Booking[]) {
    return orders.reduce((acc, booking) => {
      const year = booking.period.from.getFullYear();
      const existingValues = acc.get(year);
      existingValues ? existingValues.push(booking) : acc.set(year, [booking]);
      return acc;
    }, new Map<Year, Booking[]>());
  }

  export function groupByMonth(orders: Booking[]) {
    return orders.reduce((acc, booking) => {
      const month = booking.period.from.getMonth();
      const existingValues = acc.get(month);
      existingValues ? existingValues.push(booking) : acc.set(month, [booking]);
      return acc;
    }, new Map<Month, Booking[]>());
  }

  export function groupByYearAndMonth(groupedByYear: Map<Year, Booking[]>) {
    return [...groupedByYear].reduce(
      (acc, [year, bookings]) => acc.set(year, groupByMonth(bookings)),
      new Map<Year, Map<Month, Booking[]>>()
    );
  }

  // TODO: These names suck ass. We need better names, but boy do I not now the name.
  export function isOverridable(b: Booking) {
    return FinishedBookingStatus.some((fb) => fb === b.status);
  }

  export function isNotOverridable(b: Booking) {
    return NotFinishedBookingStatus.some((fb) => fb === b.status);
  }

  export function filterOverridable(bookings: Booking[]) {
    return bookings.filter(isOverridable);
  }

  export function filterNonOverridables(bookings: Booking[]) {
    return bookings.filter(isNotOverridable);
  }
}
