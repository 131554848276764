import React, { useMemo } from "react";

import { DaysliceConfig, GreeterEvent } from "@greeter/core";
import { useDefaultGuestApi } from "@greeter-guest/api/api";
import { useQuery } from "@tanstack/react-query";
import { GreeterEventPage } from "./GreeterEventPage";
import { useVenuesQuery } from "@greeter/guest-api-hooks";

export type GreeterEventPageApiHandlerProps = {
  greeterEventId: string;
};

export function GreeterEventPageApiHandler({ greeterEventId }: GreeterEventPageApiHandlerProps) {
  const api = useDefaultGuestApi();

  const greeterEventQuery = useQuery(
    ["greeterEvent", greeterEventId],
    async () => {
      return await api.fetchGreeterEvent(greeterEventId);
    }
  );
  const venueByEventQuery = useQuery(
    ["venueByEvent", greeterEventId],
    async () => {
      return await api.fetchVenueByEvent(greeterEventId);
    }
  );
  const greeterEventsQuery = useQuery(
    ["greeterEvents", venueByEventQuery.data?.id],
    async () => {
      if (venueByEventQuery.data)
        return await api.fetchUpcomingGreeterEventsForVenue({
          venueId: venueByEventQuery.data.id,
        });
    }
  );

  const venuesQuery = useVenuesQuery(
    api,
    greeterEventsQuery.data?.map((ge) => ge.venueId)
  );
  const daysliceConfig = useMemo(() => DaysliceConfig.createDefault(), []);

  return (
    <GreeterEventPage
      greeterEvent={greeterEventQuery.data ?? GreeterEvent.createDefault()}
      greeterEvents={greeterEventsQuery.data}
      venue={venueByEventQuery.data}
      venues={venuesQuery.data}
      daysliceConfig={daysliceConfig}
    />
  );
};
