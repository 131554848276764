import { TimeOfDay } from "@greeter/date";
import { Category } from "./Category";
import { PriceChange } from "./PriceChange";
import { Product } from "./Product";

export type MenuProduct = Product & {
  categories: string[];
  priceChanges: PriceChange[];
  originalPrice: number;
};

export module MenuProduct {
  export function filterByCategory(categoryId: string) {
    return (p: MenuProduct) => p.categories.find((c) => c === categoryId);
  }

  export function priceAfterPriceChanges(mp: MenuProduct, tz: "Europe/Copenhagen" = "Europe/Copenhagen") {
    const now = TimeOfDay.fromDate(new Date());

    for (let i = 0; i < mp.priceChanges.length; i++) {
      const pc = mp.priceChanges[i];
      const from = pc.period.from.getTotalSeconds()
      const to = pc.period.to.getTotalSeconds();
      const nowAsSeconds = now.getTotalSeconds();
      if (nowAsSeconds >= from && nowAsSeconds <= to) {
        return pc.price;
      }
    }

    return mp.originalPrice;
  }
}

export type Menu = {
  categories: Category[];
  products: MenuProduct[];
  featuredCategoryId: string;
};
