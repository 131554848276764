import { useLogin } from "@greeter-guest/contexts/LoginProvider";
import React from "react";
import { BookingSummaryPage } from "./BookingSummaryPage";
import { useDefaultGuestApi } from "@greeter-guest/api/api";
import { useQuery } from "@tanstack/react-query";
import { partial } from "lodash";
import { useAuth } from "@greeter/matter";
import {
  useDaysliceConfigQuery,
  useVenueQuery,
  useVenuesQuery,
} from "@greeter/guest-api-hooks";
import { PushNotifications } from "@capacitor/push-notifications";
import { Capacitor } from "@capacitor/core";
import { isPlatform, useIonAlert, useIonViewDidEnter } from "@ionic/react";
import { subscribeToPushNotifications } from "@greeter-guest/utility/PushNotifications";

const log = partial(console.log, "[BookingSummaryApiHandler]");

export interface BookingSummaryApiHandler {
  bookingId?: string;
}

export const BookingSummaryApiHandler: React.FC<BookingSummaryApiHandler> = ({
  bookingId,
}) => {
  const { isLoggedIn } = useAuth();
  const api = useDefaultGuestApi();

  const bookingQuery = useQuery(
    ["booking", bookingId, isLoggedIn],
    async () => {
      if (isLoggedIn && bookingId) return await api.fetchBooking(bookingId);
    }
  );

  const venueQuery = useVenueQuery(api, bookingQuery.data?.venueId ?? "");

  const upcomingGreeterEventsQuery = useQuery(
    ["upcomingGreeterEvents"],
    async () => {
      if (venueQuery.data)
        return await api.fetchUpcomingGreeterEventsForVenue({
          venueId: venueQuery.data.id,
        });
    },
    { enabled: !!venueQuery.data }
  );

  const venuesQuery = useVenuesQuery(
    api,
    upcomingGreeterEventsQuery.data?.map((ge) => ge.venueId) ?? []
  );
  const daysliceQuery = useDaysliceConfigQuery(api);

  const [showAlert] = useIonAlert();

  useIonViewDidEnter(() => {
    if (!Capacitor.isNativePlatform()) return;

    PushNotifications.checkPermissions().then((p) => {
      if (
        p.receive !== "granted" &&
        p.receive !== "denied" &&
        Capacitor.isNativePlatform() &&
        (isPlatform("ios") || isPlatform("iphone") || isPlatform("ipad"))
      ) {
        showAlert({
          header: "Vil du modtage status opdateringer til din booking?",
          message:
            "Få en notifikation i øjeblikket din booking bliver accepteret eller andre ændringer sker",
          buttons: [
            {
              text: "Nej",
            },
            {
              text: "Ja",
              handler: async () => {
                await subscribeToPushNotifications({
                  isLoggedIn,
                  guestApi: api,
                });
              },
            },
          ],
        });
      } else {
        subscribeToPushNotifications({ isLoggedIn, guestApi: api });
      }
    });
  });

  return (
    <BookingSummaryPage
      venue={venueQuery.data}
      upcomingEvents={upcomingGreeterEventsQuery.data ?? []}
      booking={bookingQuery.data}
      venues={venuesQuery.data}
      daysliceConfig={daysliceQuery.data!}
    />
  );
};
