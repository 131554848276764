import { getEnv } from "@greeter-guest/utility/ConfigHooks";
import { logger } from "@greeter/log";
import { GradientButton } from "@greeter/matter";
import { getString, getUrl } from "@greeter/url";
import { useSearchParams } from "@greeter/url";
import { IonContent, IonPage } from "@ionic/react";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useState } from "react";
import { Cupertino } from "@greeter-guest/components/Cupertino";

const name = "[PaymentPage]";
const log = logger(name);

const stripePromise = loadStripe(
  "pk_test_51JDQrGKymkLiNMTfpF3DgyAbSkicVlXov0LCFlGIZoIsjnenyV38bdNVOxhAcaJuneVnjWmCkXdPuPoIDemvMRFW00SIrp54WH",
  { betas: ["mobilepay_pm_beta_1"], locale: "da" }
);

const CheckoutForm = ({ token, redirectUrl }) => {
  const stripe = useStripe();
  const elements = useElements();
  const env = getEnv();

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const pay = async (ev: { preventDefault: () => void; }) => {
    ev.preventDefault();

    if (!elements) {
      throw new Error("Missing CardElement.");
    }

    if (!stripe) {
      throw new Error("Missing stripe.");
    }

    (async () => {
      // intent.paymentIntent?.payment_method
      // const setup = await stripe.retrieveSetupIntent(token);
      // const result = await stripe.handleNextAction({clientSecret: token});
      const result = await stripe.confirmPayment({
        elements: elements,
        redirect: "if_required",
        confirmParams: {
          return_url: redirectUrl,
        },
      });

      setErrorMessages([
        ...errorMessages,
        JSON.stringify(result.error, null, 2),
      ]);
    })();
  };

  return (
    <form
      onSubmit={pay}
      style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
    >
      <PaymentElement
        id="payment"
        options={{ wallets: { applePay: "auto", googlePay: "auto" } }}
      />
      <GradientButton disabled={!stripe}>Betal</GradientButton>
      {env === "dev" && (
        <pre
          style={{
            width: "100%",
            height: "100%",
            padding: "1rem",
            color: "green",
            backgroundColor: "black",
            textAlign: "left",
            boxSizing: "border-box",
            maxWidth: "100vw",
          }}
        >
          {errorMessages.map((m) => {
            return m;
          })}
        </pre>
      )}
    </form>
  );
};

export type PaymentPageProps = any;

export const PaymentPage: React.FC<PaymentPageProps> = () => {
  const search = useSearchParams();
  const token = getString(search, "token");
  const redirectUrl = getUrl(search, "redirectUrl");

  return (
    <Elements
      stripe={stripePromise}
      options={{ clientSecret: token, appearance: { theme: "night" } }}
    >
      <IonPage>
        <IonContent>
          <div
            style={{
              minHeight: "100vh",
              minWidth: "100vw",
              display: "grid",
              placeItems: "center",
              padding: "1rem",
            }}
          ></div>
          <Cupertino>
            <CheckoutForm token={token} redirectUrl={redirectUrl} />
          </Cupertino>
        </IonContent>
      </IonPage>
    </Elements>
  );
};

/* An example of how you can setup a custom payment button. This was working. So saved for maybe future usage
  useEffect(() => {
    if (!stripe || !elements) return;

    const paymentRequest = stripe.paymentRequest({
      country: "DK",
      currency: "dkk",
      total: { label: "Demo total", amount: 1099 },
    });

    paymentRequest.on("paymentmethod", () => {
      stripe.confirmPayment({
        elements: elements,
        confirmParams: {
          return_url: `https://greeter.dk/receipt/`,
        },
      });
    });

    (async () => {
      const canMakePayment = await paymentRequest.canMakePayment();

      if (!canMakePayment) {
        throw new Error("Cannot make payment for request...");
      }

      const btn = elements.create("paymentRequestButton", {
        paymentRequest: paymentRequest,
        style: {
          paymentRequestButton: {
            theme: "light",
          },
        },
      });

      btn.mount("#payment");
    })();

    paymentRequest.on("paymentmethod", (ev) => {

    });

  }, [stripe, elements]);
*/
