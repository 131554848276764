import React from "react";

import css from "./Receipt.module.scss";

export const ReceiptLine: React.FC<{
  left: string;
  right: string;
}> = ({ left, right }) => {
  return (
    <>
      <p className={css.Left}>{left}</p>
      <p className={css.Right}>{right}</p>
    </>
  );
};
