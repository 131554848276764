export type File = {
  name: string;
  fullPath: string;
  created: Date;
};

type ImageSizeString =
  | "1x1-w100"
  | "1x1-w150"
  | "1x1-w256"
  | "4x3-w150"
  | "4x3-w256"
  | "4x3-w512"
  | "4x3-w768"
  | "4x3-w1024"
  | "16x9-w512"
  | "16x9-w1024"
  | "16x9-w1600"

type ImageSize = {
  size: ImageSizeString;
  uri: string;
}
export type ImageAsset = {
  id: string;
  name: string;
  root: string;
  path: string;
  uri: string;
  sizes: ImageSize[];
};

export module ImageAsset {
  export function findUriWithSizeOrDefault(asset: ImageAsset, size: ImageSizeString): string {
    const _size = asset.sizes.find(withSize(size));

    if (_size) return _size.uri;

    return asset.uri;
  }

  export function withSize(size: ImageSizeString) {
    return (imageSize: ImageSize, ..._: any[]) => {
      return imageSize.size === size;
    }
  }
}
